<template>
    <!-- <div slot="head" class="page-head-default">
      <img src="../../assets/imgs/back.png" alt="" @click="onBack">
      {{i18n.code_title}}
    </div> -->
  <div class="login-code-warp">
    <div class="login-head2">
      <img class="backicon" src="../../assets/imgs/icon_morearrow.png" @click="onBack">
      {{i18n.code_title}}
      <div class="right"></div>
    </div>
    <div class="top-tips">{{i18n.verification_code}}</div>
    <div class="top-tips2">{{$t('login.vcodetophone', { phone })}}</div>
    <div class="code-input-main" @click="onFocus">
      <div class="code-input-main-item">{{code[0]}}<div class="item-cursor" v-if="isFocus&&code.length===0"></div></div>
      <div class="code-input-main-item">{{code[1]}}<div class="item-cursor" v-if="isFocus&&code.length===1"></div></div>
      <div class="code-input-main-item">{{code[2]}}<div class="item-cursor" v-if="isFocus&&code.length===2"></div></div>
      <div class="code-input-main-item">{{code[3]}}<div class="item-cursor" v-if="isFocus&&code.length>=3" :class="{'end': code.length===4}" ></div></div>
      <input id="codeinput" class="code-input-input" v-model="code" maxlength="4" type="number" @blur="onBlur"/>
    </div>
    <div class="login-code-btn">
      <div class="btn" @click="onSubmit">{{i18n.login}}</div>
    </div>
    <div class="remark">
      {{i18n.no_sms}}<br />
      <span class="again" @click="onAgain">{{i18n.retry}}</span>
    </div>
    <div class="logo">
      <van-image :src="require('@/assets/imgs/logo.png')" width="3rem"></van-image>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    phone: {
      type: String,
      default: ''
    }
  },
  computed: {
    i18n () {
      return this.$t('login')
    }
    // loginDisabled () {
    //   return this.code.length !== 4
    // }
  },
  watch: {
    isFocus (val, oldVal) {
      if (!val) {
        this.inputStatus = false
      }
    },
    code (val, oldVal) {
      if (val.length > 4) {
        this.code = val.slice(0, 4)
      }
    }
  },
  data () {
    return {
      code: '',
      isFocus: false,
      inputStatus: false
    }
  },
  methods: {
    onFocus () {
      let node = document.getElementById('codeinput')
      node.focus()
      this.isFocus = true
    },
    onBlur () {
      this.isFocus = false
    },
    onSubmit () {
      this.$emit('submit', this.code)
    },
    onAgain () {
      this.$emit('again')
    },
    onBack () {
      this.$emit('back')
    }
  }
}
</script>

<style lang="less">
.logo{
  margin: 150px 0 50px 0;
  text-align: center;
}
@keyframes blink {
  0% {
    background-color: #21DA8C;
  }
  50% {
    background-color: rgba(0, 0, 0, 0);
  }
  100% {
    background-color: #21DA8C;
  }
}
.login-code-warp {
  color: #000;
  font-size: 28px;
  background: #fff;
  padding: 0 55px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  .login-head2{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    .backicon{
      width: 50px;
      transform: rotateZ(180deg);
    }
  }
  .top-tips {
    padding: 60px 0 0px;
    text-align: center;
    font-size: 60px;
    font-weight: bold;
  }
  .top-tips2 {
    padding: 20px 0 60px;
    text-align: center;
    font-size: 30px;
  }
  .code-input-input {
    height: 1px;
    width: 1px;
    position: absolute;
    left: -100px;
    bottom: 0;
    font-size: 10px;
    caret-color: transparent;
    color: transparent;
    z-index: -999;
  }
  .code-input-main {
    height: 180px;
    line-height: 180px;
    display: flex;
    justify-content: space-between;
    position: relative;
  }
  .code-input-main-item {
    width: 100px;
    flex: 1;
    margin: 0 20px;
    border-bottom: 1px solid #D1D1D1;
    text-align: center;
    font-size: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .item-cursor {
      width: 1px;
      height: 100px;
      background: #D1D1D1;
      border-color: #D1D1D1;
      animation: blink 1s infinite steps(1, start);
    }
    .end {
      position: absolute;
      right: 0;
    }
  }
  .login-code-btn {
    padding-top: 60px;
    .btn {
      margin: 24px;
    }
  }
  .remark {
    padding-top: 20px;
    line-height: 40px;
    text-align: center;
    .again {
      color: #21DA8C;
      display: inline-block;
      font-weight: 500;
      border-bottom: 1px solid #21DA8C;
    }
  }
}
</style>
