<template>
  <page style="height: 100vh; overflow: hidden">
    <template v-if="isScan && cabinet && !isBtns">
      <div slot="nav" class="page-head-default">
        <img src="../assets/imgs/menu.png" alt="" @click="isMenuView = true">
        {{title}}
      </div>
    </template>
    <div v-else class="home-head" @click="isMenuView = true">
      <img class="home-logo" src="../assets/imgs/logo.png" alt="" />
      <div class="home-head-title">
        <p class="home-head-title-small">Welcome to</p>
        <p class="home-head-title-big">Full Charge</p>
      </div>
    </div>
    <div>
      <div class="home-warp" v-if="isScan && cabinet && !isBtns">
        <div class="banner" :style="banner"></div>
        <div class="home-warp-main">
          <div class="home-warp-block"></div>
          <div class="home-warp-cont">
            <div class="home-warp-title">
              <img src="../assets/imgs/map.png" alt="">
              <span>{{cabinet.shopname}}</span>
            </div>
            <div class="home-warp-jifei">
              <span>{{cabinet.currencyName}}{{cabinet.jifei}}</span>/{{cabinet.jifeiDanwei}}{{i18n.minute}}
            </div>
            <div class="home-warp-row">{{shopDesc}}</div>
            <div class="home-warp-row">
              <img src="../assets/imgs/icon_phone.png" alt="">
              {{i18n.feature_1}}
            </div>
            <div class="home-warp-row">
              <img src="../assets/imgs/icon_star.png" alt="">
              {{i18n.feature_2}}
            </div>
            <div class="home-warp-row">
              <img src="../assets/imgs/icon_tag.png" alt="">
              {{i18n.feature_3}}
            </div>
          </div>
          <div class="home-warp-btn" v-if="cabinet.chongzhi === 'Y'">
          <!-- <div class="home-warp-btn"> -->
            <div class="home-warp-tips">
              <img src="../assets/imgs/icon_info.png" alt="">
              <span>{{shopTips1}}</span>
            </div>
            <template v-if="browser === 'wechat'">
              <button class="btn" style="margin-bottom: 10px;" @click="onWechatPay">{{i18n.submit}} {{cabinet.currencyName}}{{amount}} {{cabinet.currency}}</button>
            </template>
            <!-- <button v-if="browser === 'wechat'" class="btn" @click="onWechatPay">{{i18n.submit}} {{cabinet.currencyName}}{{amount}} {{cabinet.currency}}</button> -->
            <button v-else-if="browser === 'alipay'" class="btn" @click="onAlipay">{{i18n.submit}} {{cabinet.currencyName}}{{amount}} {{cabinet.currency}}</button>
            <!-- <div v-else class="btn" @click="onSubmit">{{i18n.preauth}} {{cabinet.currencyName}}{{amount}} {{cabinet.currency}}</div> -->
            <div v-else-if="cabinet.yajintype === 0" class="btn" @click="onSubmit">{{i18n.preauth}} {{cabinet.currencyName}}{{amount}} {{cabinet.currency}}</div>
            <div v-else class="btn" @click="onSubmit">{{i18n.submit}} {{cabinet.currencyName}}{{amount}} {{cabinet.currency}}</div>
          </div>
          <div class="home-warp-btn" v-else>
            <div class="home-warp-tips">
              <img src="../assets/imgs/icon_info.png" alt="">
              <span>{{shopTips3}}</span>
            </div>
            <div class="btn" @click="onBorrow">{{i18n.borrow}}</div>
          </div>
        </div>
      </div>
      <div v-else>
        <img class="home-bg" src="../assets/imgs/image_main.png" alt="">
        <div class="home-foot">
          <div class="home-btns">
            <template v-if="!code">
              <a v-if="system === 'ios'" style="background: #000;" href="https://apps.apple.com/us/app/chargenow/id1584957676">
                <img src="../assets/imgs/apple.png" alt="">
                <span>App Store</span>
              </a>
              <a v-else style="background: #000;" href="https://play.google.com/store/apps/details?id=com.gzzbjkj.chargenow">
                <img src="../assets/imgs/google.png" alt="">
                <span>Google Play</span>
              </a>
            </template>
            <div class="downloaddark" v-else>
              <div class="icondark">
                <img src="../assets/imgs/appstore_dark.png" @click="onAppStore" alt="">
                <img src="../assets/imgs/googleplay_dark.png" @click="onGooglePlay" alt="">
              </div>
              <div class="next" @click="onCode">
                <div class="text">NEXT</div>
                <img src="../assets/imgs/icon_next.png" alt="">
              </div>
              <!-- <a class="next" v-if="code" @click="onCode">
                <span>{{i18n.goborrow}}</span>
              </a> -->
            </div>
          </div>
          <!-- <span class="nearby" @click="goNearby">{{i18n.nearby}} ></span> -->
        </div>
      </div>
      <popup-warp v-model="isMenuView" dialogTransition="slide-left">
        <menu-side></menu-side>
      </popup-warp>
    </div>
  </page>
</template>

<script>
import { PopupWarp } from '../components/popup'
import { MenuSide } from '../components/menu'
import { mapState } from 'vuex'
import { QR_CODE, ZH_CODE, ORDER_TEMP } from '../apis/cabinet'
import { WECHAT_MP_PRE_PAY, ALIPAY_PRE_PAY, WECHAT_MP_WXF, WECHAT_MP_WXF_CONFIRM } from '../apis/payment'
import { USER_INFO } from '../apis/user'
import wxMixin from '../mixins/wx'
import alipayMixin from '../mixins/alipay'
import { getQueryString } from '../utils/url.js'
export default {
  mixins: [wxMixin, alipayMixin],
  components: {
    PopupWarp,
    MenuSide
  },
  computed: {
    ...mapState(['qrcode', 'zhcode', 'cabinet', 'browser', 'user', 'system']),
    i18n () {
      return this.$t('home')
    },
    i18nMsg () {
      return this.$t('msg')
    },
    title () {
      return process.env.VUE_APP_NAME
    },
    code () {
      return this.qrcode || this.zhcode
    },
    scanUrl () {
      if (this.qrcode) {
        return `${QR_CODE}?id=${this.qrcode}`
      }
      if (this.zhcode) {
        return `${ZH_CODE}/${this.zhcode}`
      }
      return ''
    },
    banner () {
      return this.cabinet && this.cabinet.shoplogo ? `background-image: url(${this.cabinet.shoplogo});` : ''
    },
    shopDesc () {
      return `${this.i18n.price_info}`.format(this.cabinet)
    },
    shopTips1 () {
      return `${this.i18n.tips_1}`.format(this.cabinet)
    },
    shopTips2 () {
      return `${this.i18n.tips_2}`.format(this.cabinet)
    },
    shopTips3 () {
      return `${this.i18n.tips_3}`.format(this.cabinet)
    },
    amount () {
      if (this.cabinet) {
        return (this.cabinet.yajin - this.cabinet.myyue).toFixed(2)
      } else {
        return 0
      }
    }
  },
  data () {
    return {
      isMenuView: false,
      isScan: false,
      isBtns: false,
      isZff: true,
      checked: true,
      loopTimer: 0,
      wxcode: '',
      adVisible: false
    }
  },
  methods: {
    goRouter (path) {
      this.$router.push(path)
    },
    onScan (urlcode) {
      this.$loading(true)
      this.$post(
        `${QR_CODE}?url=${encodeURIComponent(urlcode)}`,
        {},
        resp => {
          this.$loading(false)
          // this.$store.commit('cabinet', {
          //   qrcode: this.code,
          //   ...resp
          // })
          if (resp.zujieNum && resp.zujieNum > 0) {
            this.$confirm({
              title: this.i18n.msg_fail_title,
              content: `${this.i18n.msg_rentcount}`.format(resp),
              confirmText: this.i18nMsg.vieworder,
              cancelText: this.i18nMsg.cancel
            }).then(() => {
              this.$router.push('/pastOrder')
              // this.$store.commit('qrcode', '')
            }).catch(() => {
              // this.$store.commit('qrcode', '')
            })
          } else if (resp.haveMifiBattery) {
            this.$store.commit('cabinet', {
              qrcode: this.code,
              ...resp
            })
            this.$store.commit('mificode', this.code)
            // this.$store.commit('mificode', 'DTA04096')
            this.$router.push('/selectPakage')
          } else if (resp.typeName) {
            this.$store.commit('mificode', urlcode)
            this.$router.push('/packageDetail')
          } else {
            this.$store.commit('cabinet', {
              qrcode: this.code,
              ...resp
            })
            this.isScan = true
            this.isBtns = false
            this.adVisible = true
          }
        },
        error => {
          this.$loading(false)
          this.$toast(error)
        }
      )
    },
    onSubmit () {
      this.$store.commit('wallet', null)
      // if (this.cabinet.yajintype === 1 || this.cabinet.yajintype === 2) {
      //   this.$router.push({ path: '/wallet_in', query: { price: this.amount, cabinetID: this.cabinet.qrcode, pbalance: this.cabinet.userWallet.pbalance } })
      //   return
      // }
      this.$router.push({ path: '/confirm', query: { price: this.amount, payType: 'YJ', cabinetID: this.cabinet.qrcode, yaJinType: this.cabinet.yajintype } })
    },
    onBorrow () {
      this.$loading(true)
      this.$get(
        // `${ORDER_TEMP}/${this.cabinet.qrcode}/paypal`,
        `${ORDER_TEMP}?qrcode=${this.cabinet.qrcode}`,
        {},
        resp => {
          this.$loading(false)
          console.log(resp)
          if (resp.data.outTradNo) {
            this.$router.push(`/borrowSuccess?tradeNo=${resp.data.outTradNo}&shopid=${this.cabinet.shopid}`)
          } else {
            this.$confirm({
              title: this.i18n.msg_fail_title,
              content: this.i18n.msg_fail_content,
              showCancel: false
            })
          }
        },
        error => {
          this.$loading(false)
          console.log(error)
          this.$toast(error)
        }
      )
    },
    onWechatPay (event) {
      // this.$router.push({ path: '/confirm', query: { price: this.amount, payType: 'YJ', cabinetID: this.cabinet.qrcode, yaJinType: this.cabinet.yajintype, formId: event.detail.formId } })
      this.$loading(true)
      console.log('event', event)
      const vm = this
      this.$post(
        `${WECHAT_MP_PRE_PAY}/${this.cabinet.qrcode}`,
        {
          amount: this.amount,
          formId: event.detail.formId || ''
        },
        resp => {
          this.$loading(false)
          console.log('wechat pre pay', resp)
          const data = resp.data
          wx.chooseWXPay({
            timestamp: data.timeStamp,
            nonceStr: data.nonceStr,
            package: data.package,
            signType: data.signType,
            paySign: data.paySign,
            success: function (res) {
              vm.$router.push(`/borrowSuccess?tradeNo=${data.out_trade_no}&shopid=${this.cabinet.shopid}`)
            },
            fail: function (res) {
              console.log('wxpay fail', res)
              vm.$toast(res.errMsg)
            }
          })
        },
        error => {
          console.log('wechat pre pay error', error)
          this.$loading(false)
          this.$toast(error)
        }
      )
    },
    onAlipay (event) {
      this.$loading(true)
      console.log('event', event)
      const vm = this
      this.$post(
        `${ALIPAY_PRE_PAY}/${this.cabinet.qrcode}`,
        {
          amount: this.amount
        },
        resp => {
          this.$loading(false)
          console.log('alipay', resp)
          const data = resp.data
          ap.tradePay({
            // tradeNO: data.timeStamp,
            // partnerID: data.nonceStr,
            // bizType: data.package,
            // bizSubType: data.signType,
            // bizContext: data.paySign,
            orderStr: data
          },
          (res) => {
            if (res.resultCode === 9000) {
              vm.$router.push(`/borrowSuccess?tradeNo=${data.out_trade_no}&shopid=${this.cabinet.shopid}`)
            } else {
              console.log('alipay fail', res)
              vm.$toast(res.resultCode)
            }
          })
        },
        error => {
          console.log('alipay error', error)
          this.$loading(false)
          this.$toast(error)
        }
      )
    },
    loadUser () {
      this.$get(
        USER_INFO,
        {},
        resp => {
          if (resp.user) {
            this.$store.commit('user', resp.user)
            // this.onScan(window.location.href)
            this.onScan(window.location.href)
          } else {
            this.$store.commit('user', null)
          }
        },
        error => {
          this.$store.commit('user', null)
          console.log(error)
        }
      )
    },
    onAppStore () {
      console.log('11111')
      window.location.href = process.env.VUE_APP_APP_STORE
    },
    onGooglePlay () {
      console.log('22222')
      window.location.href = process.env.VUE_APP_GOOGLE_PLAY
    },
    onCode () {
      this.isBtns = false
      if (this.wxcode) {
        this.loginMpByCode(this.wxcode)
      } else {
        this.loadUser()
      }
    },
    loginMpByCode (code) {
      this.$loading(true)
      this.$post(
        'loginByCode',
        { code },
        resp => {
          this.$loading(false)
          if (resp.token) {
            this.$store.commit('token', resp.token)
            this.wxcode = ''
            this.loadUser()
          } else {
            this.$toast(this.i18n.msg_fail_login)
          }
        },
        error => {
          this.$loading(false)
          // this.$toast(this.i18n.msg_fail_login)
          if (this.user) {
            this.loadUser()
          }
          console.log(error)
        }
      )
    },
    onWechatAuth (event) {
      if (!this.checked) {
        this.$toast('请阅读并勾选同意《委托代扣授权书》')
        return
      }
      this.$loading(true)
      console.log('event', event)
      const vm = this
      this.$post(
        `${WECHAT_MP_WXF}/${this.cabinet.qrcode}`,
        {
          amount: this.amount,
          formId: event.detail.formId || ''
        },
        resp => {
          this.$loading(false)
          console.log('wechat pre pay', resp)
          if (resp.code === 0 && resp.data && resp.data.extraData) {
            const data = JSON.parse(resp.data.extraData)
            wx.invoke(
              'openBusinessView',
              {
                businessType: 'wxpayScoreUse',
                queryString: `mch_id=${data.mch_id}&package=${resp.data.package}&timestamp=${data.timestamp}&nonce_str=${data.nonce_str}&sign_type=${data.sign_type}&sign=${data.sign}`
              },
              function (res) {
                // 从支付分返回时会执行这个回调函数
                if (parseInt(res.err_code) === 0) {
                  // 返回成功
                  vm.loopTimer = new Date().getTime()
                  vm.confirmWechatAuth(resp.data.out_trade_no)
                } else {
                  // 返回失败
                  console.log('wxpay fail', res)
                  vm.$toast('支付失败')
                  vm.isZff = false
                }
              }
            )
          } else {
            vm.isZff = false
            vm.$toast(resp.msg)
          }
        },
        error => {
          console.log('wechat pre pay error', error)
          this.$loading(false)
          this.$toast(error)
          this.isZff = false
        }
      )
    },
    onEntrust () {
      console.log('test', process.env.VUE_APP_DOMAIN_NAME)
      window.location.href = `${process.env.VUE_APP_DOMAIN_NAME}/content/wxf_auth.html`
    },
    goNearby () {
      this.$router.push('./google_map')
    },
    setDocumentTitle (title) {
      document.title = title
      if (/ip(hone|od|ad)/i.test(navigator.userAgent)) {
        var i = document.createElement('iframe')
        i.src = 'https://m.fullcharge.today/content/favicon.ico'
        i.style.display = 'none'
        i.onload = function () {
          setTimeout(function () {
            i.remove()
          }, 9)
        }
        document.body.appendChild(i)
      }
    },
    confirmWechatAuth (orderNo, loop = false) {
      if (!loop) {
        this.$loading(true)
      }
      this.$post(
        WECHAT_MP_WXF_CONFIRM,
        {
          orderNo: orderNo
        },
        resp => {
          console.log('wxf confirm', resp)
          if (resp.data) {
            this.$loading(false)
            this.$router.push(`/result?tradeNo=${orderNo}&shopid=${this.cabinet.shopid}`)
          } else {
            const timer = new Date().getTime()
            // 返回租借页面，2秒内循环检查是否租借成功
            if (timer - this.loopTimer < 2000) {
              this.confirmWechatAuth(orderNo, true)
            } else {
              this.$loading(false)
            }
          }
        },
        error => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: '确认订单状态出错，请重新尝试',
            confirmText: '重试'
          }).then(() => {
            this.confirmWechatAuth(orderNo)
          })
          console.log('wxf confirm fail', error)
        }
      )
    },
    onAuthCode () {
      this.$loading(true)
      this.$post(
        'authCodeUrl',
        {
          url: window.location.origin + window.location.pathname
        },
        resp => {
          setTimeout(() => {
            this.$loading(false)
          }, 2000)
          window.location.href = resp.data
        },
        error => {
          this.$loading(false)
          this.$toast(this.i18n.msg_fail_logi)
          console.log(error)
        }
      )
    },
    getCodeType () {
      // this.onScan('https://wifi.chargenow.top/#/?qr=1695522786')
      // this.onScan('https://app.chargenow.top/#/?qrcode=DTA04096')
      // this.onScan(window.location.href)
      // this.$store.commit('mificode', 'https://wifi.chargenow.top/#/?qr=1695522352')
      // this.$router.push('/packageDetail')
      // if (window.location.origin === 'https://app.chargenow.top') {
      // this.$store.commit('qrcode', this.$route.query.qr)
      // this.$store.commit('qrcode', 'https://app.chargenow.top/#/?qrcode=DTA04096')
      // this.$router.push('/selectPakage')
      // }
    }
  },
  created () {
    console.log('类型', window.location, this.cabinet)
    // this.$store.commit('mificode', 'https://wifi.chargenow.top/#/?qr=1695522352')
    // this.$store.commit('qrcode', 'DTA04096')
    // this.$router.push('/selectPakage')
    if (this.browser === 'wechat') {
      window.location.replace(`https://chargenow.gzz8j.com/#/${this.qrcode ? `?qrcode=${this.qrcode}` : ''}`)
      // this.loadWXScript(['chooseWXPay', 'openBusinessView'])
      // this.wxcode = this.$route.query.code || getQueryString('code')
      // if (this.wxcode) {
      //   this.loginMpByCode(this.wxcode)
      // } else if (!this.user) {
      //   this.onAuthCode()
      // } else {
      //   this.loadUser()
      // }
    } else if (this.browser === 'alipay') {
      this.isZff = false
      // this.loadAlipayScript()
      // this.loadUser()
    } else if (!this.cabinet) {
      this.$router.go(-1)
    } else if (this.$route.query.cabinetshow === 'true') {
      this.isBtns = false
      this.isScan = true
    } else {
      this.isZff = false
      this.isBtns = true
      // this.getCodeType()
      // this.loadUser()
      // // scheme协议
      // this.$nextTick(() => {
      //   console.log(process.env.VUE_APP_SCHEME)
      //   window.location.href = process.env.VUE_APP_SCHEME
      // })
      // if (!this.isBtns && this.code) {
      //   this.loadUser()
      // }
    }
  }
}
</script>

<style lang="less">
.home-head{
  width: 100%;
  font-size: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  // margin: 20px 0 0;
  padding: 46px 60px;
  .home-logo{
    width: 130px;
    height: 130px;
    border-radius: 20px;
  }
  &-title{
    p{
      text-align: end;
      margin: 10px 0;
    }
    &-small{
      font-family: 'Montserrat-Medium';
      font-size: 24px;
      color: #171917;
    }
    &-big{
      font-family: 'Montserrat-SemiBold';
      font-size: 36px;
      font-weight: 500;
      color: #000;
    }
  }
}
.home-warp {
  .banner {
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    background-color: #fff;
    background: url('../assets/imgs/banner-home.jpg') no-repeat center;
    background-size: cover;
  }
  &-main {
    width: 100%;
    height: calc(100vh - 454px);
    position: fixed;
    bottom: 0;
    left: 0;
    background: #ffffff;
    border-radius: 40px 40px 0 0;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
  }
  &-block {
    display: flex;
    justify-content: center;
    align-items: center;
    &::after {
      content: ' ';
      height: 8px;
      width: 80px;
      margin: 20px;
      border-radius: 4px;
      background: #cccccc;
    }
  }
  &-cont {
    padding: 0 48px;
  }
  &-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
  }
  &-jifei {
    padding: 60px 0 20px;
    font-size: 36px;
    font-weight: 600;
    span {
      font-size: 60px;
    }
  }
  &-row {
    display: flex;
    font-size: 24px;
    line-height: 32px;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 0;
    color: #999999;
    img {
      width: 28px;
      height: 28px;
      margin-right: 10px;
    }
  }
  &-btn {
    position: fixed;
    left: 48px;
    right: 48px;
    bottom: 48px;
    button {
      width: 100%;
      border: none;
      padding: 0;
      margin: 0;
    }
  }
  &-tips {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 40px;
    font-size: 28px;
    color: #666666;
    padding: 20px 0;
    img {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
  }
}
.home-bg {
  width: 60%;
  display: block;
  margin: 0px auto;
  // height: 100vh;
}
.home-btns {
  width: 100%;
  display: flex;
  position: fixed;
  bottom: 40px;
  justify-content: center;
  flex-direction: row;
  text-align: center;
  z-index: -1;
  a {
    text-decoration: none;
    width: 30%;
    flex: 1;
    margin: 20px;
    background: #000000;
    color: #ffffff;
    padding: 20px 0;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
  }
}
.downloaddark{
  display: flex;
  justify-content: space-between;
  margin: 0 60px;
  .icondark{
    width: 40%;
    img{
      display: block;
      width: 90%;
    }
    img:last-child{
      margin-top: 20px;
    }
  }
  .next{
    width: 40%;
    font-family: 'DIN Condensed Bold';
    font-size: 66px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: #00B578;
    border-radius: 20px;
    padding: 0px 20px;
    .text {
      // line-height: 64px;
      display: block;
    }
    img{
      width: 64px;
      height: 64px;
      margin-left: 20px;
      vertical-align: middle;
    }
  }
}
.go-code {
  text-align: center;
  color: #67C23A;
  text-decoration: underline;
  padding: 10px 0 30px;
}
.home-foot {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  .nearby {
    position: absolute;
    bottom: 170px;
    right: 20px;
    color: #fff;
    text-decoration: underline;
    text-align: center;
  }
}
.borrow-wechat-zff {
  display: flex;
  justify-content: center;
  padding: 20px 0 4px;
  img {
    height: 34px;
  }
}
.home-checkbox {
  font-size: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 18px;
  .home-checkbox-text {
    color: #52AC7A;
  }
  input[type=checkbox] {
    cursor: pointer;
    position: relative;
  }
  input[type=checkbox]::after {
    position: absolute;
    top: 0;
    background-color: #fff;
    color: #fff;
    width: 28px;
    height: 28px;
    display: inline-block;
    visibility: visible;
    padding-left: 0px;
    text-align: center;
    content: ' ';
    border-radius: 4px;
    box-sizing: border-box;
    border: 2px solid #ddd;
  }

  input[type=checkbox]:checked::after {
    content: "";
    background-color: #52AC7A;
    border-color: #52AC7A;
    background-color: #52AC7A;
  }

  input[type=checkbox]:checked::before {
    content: '';
    position: absolute;
    top: 2px;
    left: 10px;
    width: 6px;
    height: 16px;
    border: solid white;
    border-width: 0 4px 4px 0;
    transform: rotate(45deg);
    z-index: 1;
  }
}
.home-mine {
  width: 90px;
  height: 60px;
  line-height: 30px;
  padding: 15px 0;
  font-size: 28px;
  text-align: center;
  position: fixed;
  right: 40px;
  bottom: 350px;
  z-index: 99;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 8px 24px 0 rgba(52,125,255,0.32);
  // img {
  //   width: 100%;
  //   height: 100%;
  //   border-radius: 50%;
  // }
}
</style>
