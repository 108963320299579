<template>
  <page :title="i18n.title">
    <div class="balance">
      <div class="balance-title">
        {{ i18n.balance }}
      </div>
      <div class="balance-num">
        {{ wallet ? wallet.pCurrencySymbol : defaultCurrency }}{{ wallet ? wallet.pBalance : user && user.pbalance || 0 }}
      </div>
    </div>
    <div class="wallet-out-title">{{i18n.退款金额}}</div>
    <div style="padding: 20px;">
      <input class="text-area" v-model="price"/>
    </div>
    <div class="Full-withdrawal" @click="onAll">{{ i18n.balance_all }}</div>
    <div class="btn">
      <van-button class="bottom-pakage-btn" round @click="onSubmit">{{ i18n.submit }}</van-button>
    </div>
    <div v-if="checkNeedRemark" class="popup-show-modal">
      <div class="popup-show-modal-main">
        <div class="popup-show-modal-title">{{i18n.confirmtitle}}</div>
        <div class="popup-show-modal-content">{{i18n.confirmcontent}}</div>
        <div class="remarkform">
          <span>{{i18n.payee_name}}:</span>
          <input type="text" v-model.lazy="remark.name">
        </div>
        <div class="remarkform">
          <span>{{i18n.payee_bankcard}}:</span>
          <input type="text" v-model.lazy="remark.bankcard">
        </div>
        <div class="remarkform">
          <span>{{i18n.payee_phone}}:</span>
          <input type="phone" v-model.lazy="remark.phone">
        </div>
        <div class="popup-show-modal-btns">
          <div class="cancelbtn" @click="onCancel">{{i18n.cancelText}}</div>
          <div class="confirmbtn" @click="onConfirm">{{i18n.confirmText}}</div>
        </div>
      </div>
    </div>
  </page>
</template>

<script>
import { TX_APPLY, TX_POST, REFUND_APPLY } from '../../apis/user.js'
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['user', 'browser', 'wallet']),
    i18n () {
      return this.$t('wallet_out')
    },
    deposiTips () {
      return `${this.i18n.deposit_tips}`.format(this.user)
    },
    remarkstr () {
      return `姓名:${this.remark.name},银行卡号:${this.remark.bankcard},电话:${this.remark.phone}`
    },
    payWallet () {
      return this.wallet
    },
    defaultCurrency () {
      return process.env.VUE_APP_CURRENCY
    }
  },
  data () {
    return {
      price: 0,
      checkNeedRemark: false,
      NeedRemark: false,
      remark: {
        name: '',
        bankcard: '',
        phone: ''
      }
    }
  },
  methods: {
    onCancel () {
      console.log(this.remark)
      this.checkNeedRemark = !this.checkNeedRemark
      this.NeedRemark = true
      this.remark = {
        name: '',
        bankcard: '',
        phone: ''
      }
    },
    onConfirm () {
      let bool = this.remark
      if (bool.name && bool.bankcard && bool.phone) {
        this.onSubmit()
        this.checkNeedRemark = !this.checkNeedRemark
        return
      }
      this.$toast(this.i18n.msg_fail_submit)
    },
    onAll () {
      if (this.wallet) {
        this.price = this.wallet.pBalance
      } else if (this.user && this.user.pbalance) {
        this.price = this.user.pbalance
      }
    },
    onSubmit () {
      if (isNaN(this.price) || this.price <= 0) {
        this.$toast(this.i18n.msg_fail_price)
      } else if (this.wallet && (this.price > this.wallet.pBalance)) {
        this.$toast(this.i18n.msg_fail_balance)
      } else if ((this.price > this.user.pbalance) && !this.wallet) {
        this.$toast(this.i18n.msg_fail_balance)
      } else {
        this.$loading(true)
        let url = TX_APPLY
        let param = {
          amount: this.price
        }
        if (this.payWallet) {
          url = REFUND_APPLY
          param = {
            agentId: this.wallet.pDailiId,
            amount: this.price,
            refundType: 'balance',
            checkNeedRemark: this.NeedRemark // 当前提现的支付通道不支持提现为false
          }
          if (!this.NeedRemark) {
            param = {
              agentId: this.wallet.pDailiId,
              amount: this.price,
              refundType: 'balance',
              checkNeedRemark: this.NeedRemark, // 当前提现的支付通道不支持提现为false
              remark: this.remarkstr
            }
          }
        }
        if (this.browser) {
          url = `${TX_POST}/${this.price}/all`
          param = {}
        }
        this.$post(
          url,
          {
            ...param
          },
          resp => {
            this.$loading(false)
            if (this.payWallet) {
              if (resp.data && resp.data.tixianOrderId) {
                let wallet = this.wallet
                wallet.pBalance -= this.price
                this.price = 0
                this.$store.commit('wallet', wallet)
                this.$toast(this.i18n.msg_success_content)
              } else {
                this.checkNeedRemark = !this.checkNeedRemark
                this.NeedRemark = false
              }
              return
            }
            this.$toast(this.i18n.msg_success_content)
            this.$store.commit('user', {
              ...this.user,
              pbalance: this.user.pbalance - this.price
            })
            this.price = 0
            console.log(resp)
          },
          error => {
            this.$loading(false)
            this.$confirm({
              title: this.i18n.msg_title,
              content: error,
              showCancel: false,
              confirmText: this.i18n.confirm
            })
          }
        )
      }
    }
  },
  created () {
    console.log(this.wallet)
  }
}
</script>

<style lang="less" scoped>
.text-area{
  width: 100%;
  height: 80px;
    background: rgb(255, 255, 255);
    border-radius: 18px;
    opacity: 1;
    border: 2px solid rgb(209, 209, 209);
    font-size: 60px;
    line-height: 1;
    text-align: center;
}
.btn {
  margin: 40px;

  .bottom-pakage-btn {
    background: #21DA8C;
    box-shadow: 0px 6px 10px 1px rgba(0, 171, 70, 0.2);
    border-radius: 60px 60px 60px 60px;
    padding: 23px 73px;
    color: #fff;
    width: 100%;
    font-size: 36px;
    font-family: Work Sans-Bold, Work Sans;
    font-weight: bold;
  }
}
.Full-withdrawal{
font-size: 22px;
font-family: Work Sans-Regular, Work Sans;
color: #21DA8C;
text-align: center;
line-height: 2;
}
/deep/.van-search .van-cell{
  background: #fff;
}
/deep/.van-field__left-icon{
  // display: none;
  margin-right: 20px;
  .van-icon-search{
    display: none;
  }

}
.wallet-out-title{
  font-size: 32px;
  font-family: Montserrat-Bold, Montserrat;
  font-weight: bold;
  color: #1A2229;
    padding: 20px 40px;

}
.balance {
  background: #21DA8C;
  border-radius: 20px;
  width: 90%;
  height: auto;
  margin: 0 auto;
  color: #FFFFFF;
  font-family: Work Sans-Medium, Work Sans;

  &-title {
    font-size: 32px;
    padding: 20px 30px;
  }

  &-num {
    font-size: 60px;
    font-weight: bold;
    text-align: center;
    padding: 10px 0 35px 0;

  }
}
.popup-show-modal {
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  &-main {
    width: 80%;
    min-height: 220px;
    background-color: #fff;
    border-radius: 16px;
    color: #161616;
    text-align: center;
    border: 2px solid #DCDFE6;
    padding-bottom: 100px;
    position: relative;
    .remarkform{
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 0.5rem;
      span {
        width: 30%;
        text-align: start;
        font-weight: bold;
        margin: 0px 0 10px 25px;
      }
      input {
        border: solid 1px #ccc;
        border-radius: 10px;
        outline: none;
        padding: 0 10px;
        height: 40px;
        width: 60%;
      }
    }
  }
  &-title {
    font-size: 32px;
    line-height: 80px;
  }
  &-content {
    font-size: 28px;
    line-height: 40px;
    padding: 20px;
  }
  &-btns {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 80px;
    line-height: 80px;
    font-size: 28px;
    border-top: 2px solid #DCDFE6;
    display: flex;
    justify-content: flex-start;
    margin-top: 10px;
    div {
      flex: 1;
    }
    div + div {
      border-left: 2px solid #DCDFE6;
    }
    .cancelbtn{
      color: #000000;
    }
    .confirmbtn{
      color: #00ce70;
    }
  }
}
</style>
