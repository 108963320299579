<template>
  <div class="page" :style="{ 'padding-top': 9 + 'px' }">
    <div class="page-nav">
      <slot name="nav">
      </slot>
    </div>
    <div v-if="hasHead" class="page-head" :style="{ 'padding-top': 9 + 'px' }">
      <div v-if="title" class="page-head-default">
        <van-icon name="arrow-left" @click="onBack" size="0.77rem" />
        <div>{{ title }}</div>
        <div style="float: right;">
          <slot name="headleft"></slot>
        </div>

      </div>
      <slot name="head">
      </slot>
    </div>
    <div class="page-body">
      <slot></slot>
    </div>
    <div class="page-foot" v-if="hasFoot">
      <slot name="foot"></slot>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    showBack: {
      type: Boolean,
      default: true
    },
    backHandel: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(['safeArea']),
    hasHead () {
      return this.$slots.head || this.title
    },
    hasFoot () {
      return this.$slots.foot
    }
  },
  methods: {
    onBack () {
      if (this.backHandel) {
        this.$emit('onBack')
      } else {
        this.$router.go(-1)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  &-head {
    // background: #3ca2fc;
    position: fixed;
    z-index: 99;
    top: 0;
    width: 100%;
    font-size: 36px;
    height: 88px;

    &-default {
      height: 88px;
      font-size: 36px;
      font-weight: bold;
      color: #1A2229;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 40px;
        width: 40px;
        position: absolute;
        left: 32px;
      }

      .van-icon {
        position: absolute;
        left: 40px;
        width: 58px;
        height: 58px;

      }
    }
  }

  &-head+&-body {
    padding-top: 88px;
  }

  &-foot {
    background: #1A2229;
    position: fixed;
    bottom: 0;
    width: 100%;
  }

  &-nav {
    // background: #3ca2fc;
    font-size: 36px;
  }

}
.page-body{
   width: 100%;
    height: 91vh;
    display: flex;
    flex-direction: column;
}
</style>
