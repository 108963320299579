export default {
  home: {
    minute: 'minute',
    price_info: 'Free of charge for the first {mianfei} mins. A maximum daily charge of {currencyName}{fengding}. Deposit will be automatically deducted when rent reaches {currencyName}{yajin}',
    feature_1: 'Adapted to all types of mobile phones',
    feature_2: 'Can be returned to any other site',
    feature_3: 'Specially design - wireless charging(only for modules supported)',
    tips_1: 'Your {currencyName}{yajin} deposit will be refunded to you after you return the power bank.',
    yajin_info: 'A maximum daily charge of {currencyName}{fengding}',
    tips_2: 'Recharge deposit {currencyName}{yajin} can be rented, used up can be refunded',
    tips_3: 'The current balance is {currencyName}{myyue}, which can be leased directly',
    submit: 'Recharge',
    borrow: 'Rent',
    msg_fail_title: 'Error',
    msg_fail_content: 'Rental failed, please try again',
    msg_rentcount: 'You currently have {zujieNum} orders that are not closed',
    goborrow: 'Go to charge!!!',
    nearby: 'View nearby outlets',
    preauth: 'Pre-authorization',
    shopTime: 'Business hours'
  },
  button: {
    添加新卡: 'Add New Card',
    Delete: 'Delete'
  },
  selectpackage: {
    powerbank: 'Power Bank',
    powerstation: 'Power Station',
    wifibox: 'WiFi Box',
    choosepackage: 'Choose a traffic package',
    minute: 'minute',
    首次免费: 'Free of charge for the first ',
    最大每日收费: 'A maximumdaily charge of ',
    devicedeposit: 'Equipment deposit',
    return_will_back: 'Return the deposit after returning the equipment',
    lengthofuse: 'Length of use',
    amountcharged: 'Accumulated fee amount'
  },
  wifimall: {
    Select: 'Select',
    subtitle: 'Recommended packages',
    dayuse: 'Days of use',
    Verify: 'Verify',
    无限流量: 'Unlimited',
    天: 'day',
    days: 'days',
    useDay: 'Days of use',
    mypackages: 'Select an existing package binding',
    没有更多了: 'No more',
    加载中: 'loading',
    depositof: 'Includes a deposit of ',
    usagetime: 'Usage time'
  },
  paymethod: {
    payamount: 'Pay deposit',
    choosepaymethod: 'Choose Payment Options',
    addcard: 'Add New Card',
    confrimpay: 'Confirm payment',
    借记卡: 'Debit Card',
    银行卡列表: 'Bank card list'

  },
  login: {
    title: 'Sign in or sign up',
    code_title: 'SMS verification',
    phone_number: 'Phone Number',
    verification_code: 'Verification Code',
    vcodetophone: 'Code is sent to {phone}',
    captcha_code: 'Code',
    send_code: 'Send Code',
    login: 'Login',
    agree: 'By clicking Login, you agree to ',
    readme: `User Agreement`,
    msg_fail_login: 'Login failed',
    msg_success_login: 'Login successful',
    msg_fail_user: 'Error getting user information. Do you want to try again?',
    sign_in_with: 'Sign in with',
    no_sms: 'Did not receive text message',
    retry: 'Resend',
    applelogin: 'Sign in With Apple',
    googleloging: 'Sign in With Google',
    otherlogin: 'Other options',
    quicklogin: 'Quick Login',
    mobilelogin: 'Mobile Login',
    emaillogin: 'Email Login',
    facelogin: 'Sign in With FaceBook'
  },
  confirm: {
    'Credit Card': 'Credit Card',
    WeChat: 'WeChat',
    'Apple Pay': 'Apple Pay',
    'Google Pay': 'Google Pay',
    'SMS Payment': 'SMS Payment',
    'Idael Pay': 'Idael Pay',
    title: 'Payment Confirmation',
    savenow: 'Save Now',
    confirmpay: 'confirm payment',
    SMSConfirm: 'Please pay according to the text message received on your phone and click the confirm button to continue.',
    pay_label: 'Amount',
    auth_label: 'Pre-authorization',
    price_tips: 'Your balance can be withdrawn if requested. It might take 1-5 workdays to appear in your payment account.',
    auth_tips: 'A pre-authorization amount of {0}{1} is compulsory. The amount of actual rent occurred will be deducted when the powerbank is returned.',
    pay_type: 'Please select your preferred payment method',
    submit: 'Confirm payment',
    msg_loading: 'Loading...',
    msg_fail_title: 'Warning',
    msg_fail_payment: 'Failed to initiate deposit payment',
    msg_fail_order: 'Failed to create lease order',
    msg_fail_support: 'The system is not supported',
    msg_fail_confirm: 'Confirm',
    msg_fail_retry: 'Retry',
    msg_fail_cancel: 'Cancel'
  },
  mine: {
    title: 'User Profile',
    wallet: 'My Wallet',
    balance: 'My Balance',
    deposit: 'Deposit',
    wallet_in: 'Charge',
    wallet_out: 'Withdraw',
    wallet_log: 'Wallet Log',
    order: 'Order',
    coupon: 'Coupon',
    login_tips: 'Please Login',
    msg_fail_title: 'Error',
    msg_fail_update: 'Modification failed, please try again',
    msg_loading: 'Loading...',
    msg_fail_user: 'Error getting user information. Do you want to try again?',
    help: 'Help',
    setting: 'Setting',
    logout: 'Logout',
    buy: 'Buy equipment',
    aboutus: 'About us',
    contactus: 'Contact us'
  },
  msg: {
    no_phone: 'Phone number is required',
    no_email: 'Email is required',
    loading: 'Loading...',
    title: 'Hint',
    success: 'Successful operation',
    error: 'Unknown error,please try again later.',
    deletecard: 'Are you sure to delete this card?',
    confirm: 'Confirm',
    retry: 'Retry',
    cancel: 'Cancel',
    title_fail: 'Warning',
    vieworder: 'View order',
    success_payment: 'Payment completed',
    success_buy: 'Buy completed',
    fail_payment: 'Payment failure',
    keyword: 'keyword',
    nodata: 'No Data',
    copysuccess: 'Replicating Success',
    notopen: 'Not yet open',
    devicenotactivated: 'Device not activated',
    pickup_fail: 'Failed to retrieve the device. Please try again later',
    msg_none_name: 'Please enter your first name',
    msg_none_email: 'Please enter your email address',
    msg_none_mobile: 'Please enter your phone number',
    msg_none_nation: 'Please enter your country',
    msg_none_msg: 'Please enter other information that is convenient for identifying you',
    msg_fail_choosepackage: 'Please select a package pickup machine',
    geolocationfailed: 'Error: The Geolocation service failed.'
  },
  result: {
    title: 'Result Confirmation',
    price_plan: 'Charge Standard',
    msg_success: 'Successfully Rented',
    msg_tips_loading: 'Portable battery is popping up, please wait patiently...',
    msg_result_loading: 'The payment result is being confirmed...',
    msg_fail_confirm: 'Confirm',
    btn_usage: 'Usage note',
    btn_return: 'Return note',
    deposit: 'Deposit',
    msg_fail_content: 'Reqest failed, please try again',
    price_label: 'per hour',
    price_info: 'Free of charge for the first {mianfei} mins，A maximum dialy charge of {ccurrency}{fengding}，Deposit will be automatically deducted when rent reaches {ccurrency}{yajin}',
    tips_1: 'Please collect power bank <span class="number">No.{number}</span> as the picture shown',
    tips_2: 'Please tidy the cables and insert the power bank to bottom with the <span class="highlight">[arrow downward]</span> when return',
    msg_note_usage: 'The power bank is equipped with 3 types of cables and wireless changing,please use as needed.',
    msg_note_return: 'Please tidy this cables and insert the power bank to the bootom with the [arrow downward] when return.',
    return_info: 'When return,set the cables well and insert the side with metal chips into an empty socket till it reaches the bottom and stabilized.'
  },
  result_msg: {
    loading: 'Loading...',
    refresh: 'Refresh'
  },
  order: {
    title: 'Lease Order',
    in_use: 'In use',
    ended: 'Ended',
    msg_error: 'Request failed'
  },
  order_item: {
    order_number: 'No.',
    venue_rented: 'Venue rented',
    time_rented: 'Time rented',
    venue_returned: 'Venue returned',
    time_returned: 'Time returned',
    charge_standard: 'Charge standard',
    charge_standard_info: '{pJifei} {pCurrency} per hour(first {pMian} mins for free)',
    rent_occurred: 'Rent occurred',
    returned: 'Returned',
    unreturned: 'Unreturned',
    type_0: 'Not Completed',
    type_1: 'Renting',
    type_2: 'Canceled',
    type_3: 'Returned',
    type_4: 'Fault occurred',
    type_5: 'Overtime',
    min: 'Min',
    payment: 'Payment'
  },
  setting: {
    title: 'Setting',
    lang: 'Language'
  },
  help: {
    title: 'FAQ'
  },
  wallet_in: {
    title: 'Balance Recharge',
    price_label: 'Recharge amount',
    price_placeholder: 'Please enter the recharge amount',
    balance: 'Current balance',
    price_auto: 'Choose Payment Amount',
    price_tips: 'Your balance can be withdrawn if requested. It might take 1-5 workdays to appear in your payment account.',
    submit: 'Submit',
    loading: 'Loading...',
    paying: 'Jump to the payment page...',
    msg_title: 'Tips',
    msg_fail_order: 'Failed to create recharge order',
    msg_fail_payment: 'Failed to initiate recharge payment',
    msg_fail_retry: 'Retry',
    选择充值金额: 'Select the recharge amount',
    输入充值金额: 'Enter the recharge amount',
    确认充值: 'Confirm the recharge'
  },
  wallet_out: {
    title: 'Withdrawal',
    price_label: 'Withdrawal amount',
    price_placeholder: 'Please enter the withdrawal amount',
    balance: 'Current balance',
    balance_all: 'Withdraw all',
    deposit: 'Deposit',
    deposit_tips: 'There is an lease order. The deposit of {pcurrencyName}{pyajin} is non-refundable',
    submit: 'Submit',
    apply: 'Apply',
    confirm: 'Confirm',
    loading: 'Loading...',
    msg_fail_price: 'The withdrawal amount is incorrect',
    msg_fail_balance: 'The withdrawal amount cannot be greater than the current balance',
    msg_fail_submit: 'Please fill in the complete information',
    msg_title: 'Error',
    msg_success_content: 'Apply Refund successful',
    confirmtitle: 'Tips',
    confirmcontent: 'The current wallet does not support cash withdrawal. Please note your contact information and refund manually',
    payee_name: 'Payee Name',
    payee_bankcard: 'Bank card number',
    payee_phone: 'Phone',
    cancelText: 'cancel',
    confirmText: 'Reapply',
    退款金额: 'Refund amount'

  },
  map: {
    title: 'Near the equipment',
    result_msg: 'No equipment was found nearby',
    msg_title: 'error',
    no_support: 'sorry! Your browser does not support location',
    my_position: 'my location',
    distance: 'distance:',
    shop_time: 'hours',
    free: 'loanable',
    return: 'returnable',
    shop_title: 'Merchant details',
    address: 'address',
    go_here: 'Go here',
    在线: 'on line',
    离线: 'offline',
    scanQRCode: 'Scan QR code'
  },
  scanOrder: {
    confirmpickup: 'Confirmation of pickup',
    QRpickup: 'QR code pickup',
    取机中: 'Pick -up',
    scanQR: 'Scan the QR code to remove',
    qrCodePickup: 'QR code pickup',
    orderNo: 'Order No.',
    startingTime: 'Starting time',
    endTime: 'End time',
    packageStatus: 'Package status',
    无限流量: 'Unlimited',
    天: 'day',
    days: 'days',
    type_0: 'Unused',
    type_1: 'To be activated',
    type_2: 'activated',
    type_3: 'Revoked',
    type_4: 'refunded',
    type_9: 'over',
    type_33: 'unpaid',
    type_88: 'Cancelled',
    inputserialNumber: 'Please enter the serialNumber of the device',
    pickupsuccess: 'Successfully retrieved the machine',
    continuepay: 'Continue payment'
  },
  borrowSuccess: {
    success: 'success',
    renewalTraffic: 'Renewal traffic',
    viewOrder: 'View Order',
    HowUseDevice: 'How to use the device'
  },
  packageOrder: {
    viewdetail: 'View Details',
    buynow: 'Buy Now',
    isrent: 'Is the equipment rented?',
    timelimited: 'Time-limited offer'
  },
  packageDetail: {
    WiFiName: 'WiFi Name',
    WiFiPassword: 'WiFi Password',
    batteryLife: 'Battery Power',
    Signal: 'Signal',
    orderNo: 'Order No.',
    startingTime: 'Starting time',
    endTime: 'End time',
    packageStatus: 'Package status',
    usedTraffic: 'Used Traffic',
    Renewals: 'Renewals',
    inuse: 'In Use'
  },
  orderDetail: {
    payment: 'Payment',
    orderNo: 'order No',
    trafficDetails: 'See package traffic details',
    Renewals: 'Renewals',
    FeeSchedule: 'Fee Schedule',
    HowToUse: 'How to use',
    LeaseMerchant: 'Lease Merchant',
    startTime: 'start Time',
    ReturningMerchants: 'Returning Merchants',
    EndTime: 'End time',
    Deposit: 'Deposit',
    fee1: 'Equipment deposit $500',
    fee2: 'Returned within 7 days after the expiration date of the rental, minus $10/day/unit.',
    fee3: 'If the equipment is returned within 7 days after the rental period expires, $200/unit will be deducted.',
    fee4: 'The deposit will be deducted automatically ifthe equipment is not returned more than 15days after the expiration of the rental days.',
    rentagain: 'Renting Again'
  },
  pickUp: {
    orderNo: 'Order No.',
    startingTime: 'Starting time',
    endTime: 'End time',
    packageStatus: 'Package status',
    confirmationOfPickup: 'confirmation of pickup'
  },
  aboutus: {
    title: 'About us',
    desc1: 'Power Bank is a shared solution brand of Power Bank under Shenzhen Bajie Charging Technology Co., Ltd. Since its establishment in 2018, Shenzhen Bajie Charging Technology Co., Ltd. has been focusing on the research and operation of intelligent terminal devices in the sharing economy field. Through continuous technological innovation and application expansion, we have developed various intelligent terminal devices such as shared power banks, shared intelligent storage cabinets, shared global WiFi, and shared portable energy storage to meet the needs of different users and scenarios. We lead the market with innovation and promote the sharing economy business model to various parts of the world by deeply understanding and meeting user needs.',
    desc2: 'BaJie Charging has developed a mature OEM cooperation model for our business partners. We can provide you with our equipment and technical support, and you can operate your own power bank shared service brand.',
    contactustitle: 'Interested in becoming a partner of Chargenow? Please contact us——',
    formtitle: 'Have a Question? Contact Us!',
    submit: 'Submit',
    name: 'Name',
    email: 'E-mail',
    tel: 'Tel',
    nation: 'Nation',
    message: 'Message'
  },
  contactus: {
    title: 'Contact Us',
    desc1: 'Chargenow is a power bank sharing solution brand under Shenzhen Bajie Charging technology company. Chargenow operates a power bank sharing service, with franchisees located throughout the world.',
    desc2: 'If you are interested in joining our franchise business, please contact our headquarters in China.',
    contactustitle: 'China Headquarters',
    contactussubtitle: '7f, Building 3, Jinxing High-Tech Park, No. 25 Jian\'an Road, Fuyong Town, Bao\'an District, Shenzhen Guangdong, China.',
    contactusinfotitle: 'For end users who encounter issues while renting a shared power, please reach out to your local franchisee. Here are their respective contact information and addresses:',
    method1: 'GRANSOL BRASIL',
    methoddesc1: 'Av. Ana Costa, 61 EV 506 Santos/SP CEP 11060-001',
    method2: 'Kazakhstan Region',
    methoddesc2: 'Aktau City'
  },
  pastOrder: {
    payment: 'payment'
  },
  scanQr: {
    NotAllowedError: 'Necesitas otorgar acceso a la cámara',
    NotFoundError: 'No hay cámara en este dispositivo',
    NotSupportedError: 'Contexto de seguridad requerido (HTTPS, localhost)',
    NotReadableError: 'La cámara está ocupada.',
    OverconstrainedError: 'La instalación de la cámara es inapropiada.',
    StreamApiNotSupportedError: 'Este navegador no admite API de transmisión'
  }
}
