import bmz from '../assets/imgs/AreaIcon/bmz.png'
import dny from '../assets/imgs/AreaIcon/dny.png'
import dy from '../assets/imgs/AreaIcon/dy.png'
import dyz from '../assets/imgs/AreaIcon/dyz.png'
import fz from '../assets/imgs/AreaIcon/fz.png'
import nmz from '../assets/imgs/AreaIcon/nmz.png'
import ny from '../assets/imgs/AreaIcon/ny.png'
import oz from '../assets/imgs/AreaIcon/oz.png'
import qq from '../assets/imgs/AreaIcon/qq.png'
import xy from '../assets/imgs/AreaIcon/xy.png'
import zmz from '../assets/imgs/AreaIcon/zmz.png'
import zy from '../assets/imgs/AreaIcon/zy.png'

export const areaList = [
  {
    label: '全球',
    src: qq
  }, {
    label: '北美洲',
    src: bmz
  },
  {
    label: '东南亚',
    src: dny
  },
  {
    label: '东亚',
    src: dy
  },
  {
    label: '大洋洲',
    src: dyz
  },
  {
    label: '非洲',
    src: fz
  },
  {
    label: '南亚',
    src: ny
  },
  {
    label: '南美洲',
    src: nmz
  },
  {
    label: '欧洲',
    src: oz
  },
  {
    label: '西亚',
    src: xy
  },
  {
    label: '中美州',
    src: zmz
  },
  {
    label: '中亚',
    src: zy
  },
  {
    label: '加勒比海',
    src: qq
  }

]
