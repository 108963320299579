<template>
  <div class="login-warp">
    <div class="login-head">
      <img class="backicon" src="../../assets/imgs/icon_morearrow.png" @click="onBack">
    </div>
    <div class="login-title">{{ i18n.title }}</div>
    <div class="login-logintype">
      <div v-for="(item, i) in tabList" :key="i" :class="{'logincheck': state === item.value}" @click="state = item.value">
        {{ item.label }}
      </div>
    </div>
    <template v-if="state === 1">
      <div class="login-row">
        <input type="email" v-model.trim="email" :placeholder="i18n.emaillogin">
      </div>
      <!-- <login-facebook @success="loadUser">
        <img src="../../assets/imgs/icon_facebook.png" alt="" />
        <span>{{ i18n.facelogin }}</span>
      </login-facebook>
      <login-apple v-if="system === 'ios'" @success="loadUser">
        <button class="btn appleloginbtn2" id="sign-in-with-apple-button">
          <img src="../../assets/imgs/icon_applepay.png" alt="">
          <span>{{ i18n.applelogin }}</span>
        </button>
      </login-apple>
      <login-google class="googlelogin" v-else @success="loadUser">
        <div id="buttonDiv"></div>
        <div class="btn googlewarp">
          <img src="../../assets/imgs/icon_google.png" alt="">
          <span>{{ i18n.Google }}</span>
        </div>
      </login-google> -->
    </template>
    <template v-else-if="state === 2">
      <div class="login-row">
        <div class="area" @click="isAreaView = true">
          <span>+{{ areaCode }}</span>
          <div class="area-down"></div>
        </div>
        <input type="number" v-model="phone" :placeholder="i18n.phone_number">
      </div>
      <div class="login-row">
        <input type="number" v-model="code" :placeholder="i18n.captcha_code">
        <div class="login-code" @click="onCaptcha">
          <img :src="captchaUrl" />
        </div>
      </div>
    </template>
    <div class="login-info">
      <span>{{ i18n.agree }}
        <span class="readme" @click="onReadMe">{{ i18n.readme }}</span>
      </span>
    </div>
    <div class="btn" @click="onSendBefore">{{ i18n.login }}</div>
    <div class="login-foot">
      <login-apple v-if="system === 'ios'" @success="loadUser">
        <button class="btn appleloginbtn2" id="sign-in-with-apple-button">
          <img src="../../assets/imgs/icon_applepay.png" alt="">
          <span>{{ i18n.applelogin }}</span>
        </button>
      </login-apple>
      <login-google class="googlelogin" @success="loadUser">
        <div id="buttonDiv"></div>
        <div class="btn googlewarp">
          <img src="../../assets/imgs/icon_google.png" alt="">
          <span>{{ i18n.googleloging }}</span>
        </div>
      </login-google>
    </div>
    <div class="logo">
      <van-image :src="require('@/assets/imgs/logo.png')" width="3rem"></van-image>
    </div>
    <select-area v-model="isAreaView" @select="onSelct"></select-area>
    <login-code v-if="isLoginCode" :phone="contactNum" @submit="onLogin" @again="onBack" @back="onBack"></login-code>
  </div>
</template>

<script>
import md5 from 'js-md5'
import { SelectArea } from '../../components/select'
import { generateUUID } from '../../utils/index'
import { baseURL } from '../../utils/http'
import { USER_CAPTCHA, LOGIN_CODE, SEND_CODE, USER_INFO, SEND_MAIL, LOGIN_EMAIL } from '../../apis/user'
import { LoginCode, LoginGoogle, LoginApple } from '../../components/login'
import { mapState } from 'vuex'
export default {
  components: {
    SelectArea,
    LoginCode,
    // LoginFacebook,
    LoginGoogle,
    LoginApple
  },
  computed: {
    ...mapState(['user', 'browser', 'system', 'mifiOrder']),
    i18n() {
      return this.$t('login')
    },
    i18nMsg() {
      return this.$t('msg')
    }
  },
  watch: {
    user(val, oldVal) {
      if (val && !oldVal) {
        this.$router.push('/')
      }
    },
    state (val) {
      if (val && val === 2) {
        this.onCaptcha()
      }
    }
  },
  data() {
    return {
      isAreaView: false,
      areaCode: '1',
      phone: '',
      code: '',
      captchaUrl: '',
      uuid: '',
      isLoginCode: false,
      tabList: [],
      state: 2,
      email: '',
      contactNum: ''
    }
  },
  methods: {
    onBack() {
      this.$router.go(-1)
    },
    onReadMe() {
      window.open(process.env.VUE_APP_PACT)
    },
    onSelct(val) {
      this.areaCode = val
    },
    onCaptcha() {
      this.code = ''
      this.uuid = generateUUID()
      this.captchaUrl = `${baseURL}${USER_CAPTCHA}?uuid=${this.uuid}`
    },
    onLogin(code) {
      this.$loading(true)
      let url = `${LOGIN_CODE}?mobile=${this.areaCode + this.phone}&verifyCode=${code}&areaCode=${this.areaCode}&registAndLoginType=1`
      if (this.state === 1) {
        url = `${LOGIN_EMAIL}?email=${this.email}&verifyCode=${code}`
      }
      this.$post(
        url,
        {
          // mobile: this.areaCode + this.phone,
          // verifyCode: code,
          // areaCode: this.areaCode,
          // registAndLoginType: 1
        },
        res => {
          console.log(res)
          this.$loading(false)
          if (res.token) {
            this.$store.commit('token', res.token)
            this.loadUser()
          } else {
            this.$toast(this.i18n.msg_fail_login)
          }
        },
        err => {
          console.log(err)
          this.$toast(err)
          this.$loading(false)
        }
      )
    },
    onSendBefore () {
      if (this.state === 1) {
        this.contactNum = this.email
        this.onSendMail()
      } else {
        this.contactNum = this.phone
        this.onSendCode()
      }
    },
    onSendCode() {
      this.$loading(true)
      let url = SEND_CODE
      this.$post(
        url,
        {
          mobile: this.areaCode + this.phone,
          uuid: this.uuid,
          code: this.code
          // useDebug: 'true'
        },
        res => {
          console.log(res)
          this.$loading(false)
          this.isLoginCode = true
        },
        err => {
          console.log(err)
          this.$loading(false)
          this.$toast(err)
        }
      )
    },
    onSendMail() {
      if (!this.email) {
        this.$toast(this.i18nMsg.no_email)
        return
      }
      this.$loading(true)
      let url = SEND_MAIL
      let timp = new Date().valueOf()
      let str = timp.toString().replace(/0/g, '8')
      let mdsign = md5((str + 'GZzbjkj'))
      this.$post(
        url,
        {
          email: this.email,
          timestamp: timp,
          sign: mdsign
        },
        res => {
          console.log(res)
          this.$loading(false)
          this.isLoginCode = true
        },
        err => {
          console.log(err)
          this.$loading(false)
          this.$toast(err)
        }
      )
    },
    // onBack () {
    //   this.isLoginCode = false
    //   this.onCaptcha()
    // },
    loadUser() {
      this.$loading(true)
      this.$get(
        USER_INFO,
        {},
        res => {
          this.$loading(false)
          if (res.user) {
            this.$store.commit('user', res.user)
            this.$toast(this.i18n.msg_success_login)
          } else {
            this.$confirm({
              title: this.i18n.msg_fail_title,
              content: this.i18n.msg_fail_user
            }).then(() => {
              this.loadUser()
            }).catch(() => {
              this.$store.commit('token', null)
            })
          }
        },
        err => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: err,
            showCancel: false
          }).then(() => {
            this.$store.commit('token', null)
          })
        }
      )
    }
  },
  created() {
    this.tabList = [{
      label: this.i18n.emaillogin,
      value: 1
    }, {
      label: this.i18n.mobilelogin,
      value: 2
    }]
    if (this.browser === 'wechat') {
      this.$router.push('/login_wechat')
    } else if (this.browser === 'alipay') {
      this.$router.push('/login_alipay')
    }
    // const langCode = window.navigator.language.substring(3)
    // let temp = AreaCodeDate.find(item => item.code === langCode)
    // if (temp && temp.value) {
    //   this.areaCode = temp.value
    // }
  },
  mounted() {
    this.onCaptcha()
  }
}
</script>

<style lang="less" scoped>
#buttonDiv {
  opacity: 0;
  position: relative;
  z-index: 9;
}

.buttonDiv /deep/ .S9gUrf-YoZ4jf {
  iframe {
    width: 100% !important;
    margin: 0 auto !important;
  }
}

.buttonDiv {
  position: relative;
  z-index: 99;
  top: 0;
  width: 45%;
  // opacity: 0;
}
.googlewarp {
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  background: #fff;
  color: #3c4043;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  // padding: 20px 0;
  margin-top: 0px!important;
  img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
}
.logo {
  margin: 250px 0 50px 0;
  text-align: center;
}

.login-warp {
  padding: 0 40px;
  height: 100vh;
  background: #fff;
  color: #000;

  .login-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;

    .backicon {
      width: 50px;
      transform: rotateZ(180deg);
    }
  }

  .login-title {
    font-size: 56px;
    font-weight: bold;
  }

  .login-logintype {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;

    div {
      width: 30%;
      text-align: center;
      margin-left: 10px;
      padding: 10px 5px;
      border: solid 1px #21DA8C;
      border-radius: 100px;
      // color: #fff;
    }
  }

  .logincheck {
    background: #21DA8C;
    border: solid 1px #21DA8C;
    color: #fff;
  }

  .login-row {
    margin: 36px 0;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 4px solid #21DA8C;

    // color: #fff;
    .area {
      width: 100px;
      padding: 0 10px;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }

    input {
      font-size: 32px;
      width: 300px;
      flex: 1;
      height: 80px;
      padding: 0 20px;
      margin: 0;
      border: none;
      outline: none;
      background-color: rgba(0, 0, 0, 0);
    }

    // input::placeholder {
    //   color: #fff;
    // }
    .area-down {
      // margin-left: 10px;
      width: 0;
      height: 0;
      border-width: 10px 10px 0;
      border-style: solid;
      border-color: #000 transparent transparent;
    }

    .login-code {
      width: 180px;
      height: 60px;
      background-image: linear-gradient(to right, rgba(194, 194, 194, 1), rgba(252, 252, 252, 1));

      img {
        width: 180px;
        height: 60px;
      }
    }
  }

  .btn {
    margin: 120px 0px 0 0;
  }

  .login-foot {
    width: 100%;
    margin-top: 10%;

    img {
      max-width: 100%;
      max-height: 100%;
    }

    .appleloginbtn2 {
      width: 100%;
      border: none;
      background: #fff;
      color: #000;
      margin: 40px 0 0;

      img {
        width: 50px;
        margin-right: 20px;
      }
    }

    .googlelogin {
      margin-top: 40px;
      width: 100%;
      position: relative;
    }
  }

  .login-info {
    font-size: 24px;
    font-weight: 400;
    color: #000;
    line-height: 33px;
    display: flex;
    justify-content: flex-start;
  }

  .login-info .readme {
    font-weight: 500;
    color: #31C889;
    margin-left: 10px;
    display: inline;
  }
}
</style>
