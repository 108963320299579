<template>
  <div id="app">
    <router-view />
    <popup-loading v-if="loading"></popup-loading>
  </div>
</template>

<script>
import { PopupLoading } from './components/popup'
import { mapState } from 'vuex'

export default {
  components: {
    PopupLoading
  },
  computed: {
    ...mapState(['loading'])
  }
}
</script>

<style lang="less">
@import './assets/css/public.less';

body {
  background: #F9F9F9;
  padding: 0;
  margin: 0;
  height: 100vh;
  color: #333333;
font-family: Work Sans-Regular, Work Sans;

}
.p-2{
  padding: 20px;
}
#app {
  font-size: 28px;
}
.btn {
  height: 86px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background: #21DA8C;
  box-shadow: 0px 6px 10px 1px rgba(49, 200, 137, 0.2);
  border-radius: 60px 60px 60px 60px;
  font-size: 32px;
  font-weight: bold;
}
.text-success {
  color: #21DA8C;
}
</style>
