<template>
  <page :title="i18n.title">
    <div class="confirm-warp">
      <div class="vcard flex-s pay-amount">
        <div class="pay-amount-title">{{ i18nPay.payamount }}</div>
        <div style="color: #21DA8C;" class="flex-c">
          <span class="doller">{{ currency }}</span>
          <span class="pay-price">{{ price }}</span>
        </div>
      </div>
      <div class="pay-h1 ">{{ i18nPay.choosepaymethod }}</div>
      <div class="confirm-payment">
        <!-- <template v-if="payType === 'YJ'">
          <div v-for="(item, i) in cardList" :key="i" class="confirm-payment-item" @click="onPayMode(item, true)">
            <div class="confirm-payment-item-info">
              <img :src="require('../../assets/imgs/icon_card@2x.png')" />
              <div class="content">
                <div class="title">{{ item.pCardnumber }}</div>
              </div>
            </div>
            <div class="confirm-payment-item-checked">
              <img v-if="payMode.pId === item.pId && item.currencyName === payMode.currencyName" src="../../assets/imgs/checkbox_selected.png" />
              <img v-else src="../../assets/imgs/checkbox.png" />
            </div>
          </div>
        </template> -->
        <template v-for="item in payModeList">
          <div v-if="item.phone === 'all' || item.phone === system || item.phone === browser || (item.phone === 'phone' && system !== '')" :key="item.pId" class="confirm-payment-item" @click="onPayMode(item)">
            <div class="confirm-payment-item-info">
              <img :src="item.pPaymentType === 'GOOGLE_PAY' || item.pPaymentType === 'STRIPE_GOOGLE' ? require('../../assets/imgs/icon_googlepay.png') : item.pPaymentType === 'APPLE_PAY' || item.pPaymentType === 'STRIPE_APPLE' ? require('../../assets/imgs/icon_applepay.png') : require('../../assets/imgs/icon_card@2x.png')" />
              <div class="content">
                <div class="title">{{ $t('confirm.' + item.label).includes('confirm.') ? item.label : $t('confirm.' + item.label) }}</div>
                <div v-if="item.pPaymentType === 'STRIPE_CARD'" class="sub_title">{{ i18n.auth_label }}</div>
              </div>
            </div>
            <div class="confirm-payment-item-checked">
              <img v-if="payMode === item.pPaymentType && item.currencySymbol === currencySymbol" src="../../assets/imgs/checkbox_selected.png" />
              <img v-else src="../../assets/imgs/checkbox.png" />
            </div>
          </div>
        </template>
        <!-- <div class="confirm-payment-item" @click="goRouter('/wallet')">
          <div class="confirm-payment-item-info">
            <div class="content">
              <div class="title">{{ i18nButton.添加新卡 }}</div>
            </div>
          </div>
          <div class="confirm-payment-item-checked">
            <img src="../../assets/imgs/icon_more.png" />
          </div>
        </div> -->
      </div>
      <div class="confirm-btns">
        <div class="btn" @click="onPay" :disabled="disabled">{{ i18n.submit }}</div>
      </div>
      <popup-warp v-model="isCreditcard">
        <div>
          <div class="creditcard-input">
            <div id="credit-card-element"></div>
          </div>
          <div class="btn creditcard-btn" @click="onStripeSetup">{{ i18n.confirmpay }}</div>
        </div>
      </popup-warp>
      <popup-warp v-model="isDebitcard">
        <div>
          <div class="creditcard-input">
            <div id="debit-card-element"></div>
          </div>
          <div class="btn creditcard-btn" @click="onStripePay">{{ i18n.confirmpay }}</div>
        </div>
      </popup-warp>
      <popup-warp v-model="isIdael">
        <div class="p-2">
          <div id="link-authentication-element"></div>
          <div id="payment-element"></div>
          <div class="btn creditcard-btn" @click="onIdaelPay">{{ i18n.confirmpay }}</div>
        </div>
      </popup-warp>
    </div>
  </page>
</template>

<script>
import { mapState } from 'vuex'
import { getQueryString } from '../../utils/url.js'
import { getToken } from '../../utils/stripe_pay.js'
import { STRIPE_PREPAY_SPTOKEN, STRIPE_PAYORDER_SPTOKEN, PAYPAL_PRE_PAY,
  PAYPAL_RECHARGE, STRIPE_PAY_OF_CONFIRM, STRIPE_RECHARGE_OF_CONFIRM, STRIPE_PAY_CONFIRM,
  STRIPE_PREAUTH_OF_CONFIRM, STRIPE_PREAUTH_AUTO_CONFIRM, PAYMODE, WECHAT_MP_PRE_PAY,
  STRIPE_PAYORDER, STRIPE_RECHARGE_WALLET, MIFI_PREAUTHPAY, MIFI_PREAUTHCONFIRM,
  MIFI_RENT, MIFI_WAKEUPPAY, STRIPE_CARD_PAY } from '../../apis/payment'
import { USER_CARD } from '../../apis/user.js'
import { ORDER_TEMP } from '../../apis/cabinet'
import stripeMixin from '../../mixins/stripe'
import { PopupWarp } from '../../components/popup'
export default {
  mixins: [stripeMixin],
  components: {
    PopupWarp
  },
  computed: {
    ...mapState(['system', 'browser', 'cabinet', 'user', 'qrcode', 'wallet', 'mifiOrder', 'mificode', 'agentOpenId', '_clientSecret']),
    i18n () {
      return this.$t('confirm')
    },
    i18nMsg () {
      return this.$t('msg')
    },
    i18nPay () {
      return this.$t('paymethod')
    },
    i18nButton () {
      return this.$t('button')
    },
    currency () {
      if (this.cabinet) {
        return this.cabinet.currencyName
      } else if (this.mifiOrder) {
        return this.mifiOrder.currencyName
      } else {
        return this.user.pcurrencyName
      }
    },
    params () {
      let datas = {
        qrcode: this.mificode || this.qrcode,
        paymentId: this.pId,
        ejectOrNot: this.mifiOrder && this.mifiOrder.xugou ? 0 : 1,
        orderSubmit: {
          orderType: 0,
          currency: this.mifiOrder.currency,
          totalAmount: this.price,
          orderRemark: '设备+套餐',
          submitDtlDtoList: [
            {
              remark: ''
            }
          ]
        }
      }
      if (this.mifiOrder.productSendSn) {
        datas.orderSubmit.orderType = 1
        datas.orderSubmit.orderRemark = '续费套餐'
        datas.orderSubmit.submitDtlDtoList[0].productSendSn = this.mifiOrder.productSendSn
      } else if (this.mifiOrder.productId && !this.mifiOrder.skuId) {
        datas.orderSubmit.orderType = 3
        datas.orderSubmit.orderRemark = '仅设备'
      } else if (!this.mifiOrder.productId && this.mifiOrder.skuId) {
        datas.orderSubmit.orderType = 2
        datas.orderSubmit.orderRemark = '仅套餐'
      }
      if (this.mifiOrder && this.mifiOrder.productId) {
        datas.orderSubmit.submitDtlDtoList[0].productId = this.mifiOrder.productId
        datas.orderSubmit.submitDtlDtoList[0].productAmount = 0
        datas.orderSubmit.submitDtlDtoList[0].depositAmount = this.mifiOrder.depositAmount
      }
      if (this.mifiOrder && this.mifiOrder.skuId) {
        datas.orderSubmit.submitDtlDtoList[0].skuId = this.mifiOrder.skuId
        datas.orderSubmit.submitDtlDtoList[0].skuPriceId = this.mifiOrder.id
        datas.orderSubmit.submitDtlDtoList[0].packageName = this.mifiOrder.packageName
        datas.orderSubmit.submitDtlDtoList[0].packagePrice = this.mifiOrder.price
        datas.orderSubmit.submitDtlDtoList[0].reserverStartTime = this.mifiOrder.startTime
        datas.orderSubmit.submitDtlDtoList[0].reserverEndTime = this.mifiOrder.endTime
        datas.orderSubmit.submitDtlDtoList[0].timeZoneList = JSON.parse(this.mifiOrder.countryTimeZoneList[0].timeZone)
      }
      if (this.mifiOrder && this.mifiOrder.pickUpOrderId) {
        datas.orderSubmit.submitDtlDtoList[0].pickUpOrderId = this.mifiOrder.pickUpOrderId
      }
      if (this.mifiOrder && this.mifiOrder.mifiOrderId) {
        datas.orderSubmit.submitDtlDtoList[0].mifiOrderId = this.mifiOrder.mifiOrderId
      }
      return datas
    }
  },
  watch: {
    payMode (val, oldVal) {
      console.log('payMode', val, typeof val)
      if (val === 'APPLE_PAY' || val === 'STRIPE_APPLE' || val === 'STRIPE_GOOGLE' || val === 'GOOGLE_PAY') {
        if (!this.stripePayRequest) {
          this.disabled = true
          this.$loading(true)
          this.getStripeToken()
        } else {
          this.disabled = false
        }
      } else {
        this.disabled = false
      }
    },
    stripePayRequest (val, oldVal) {
      this.disabled = !val
    }
  },
  data () {
    return {
      price: 0,
      payType: 'YJ',
      formId: '',
      cabinetID: '',
      yaJinType: null,
      pdailiId: '',
      payMode: 'STRIPE_CARD',
      pId: '', // 代理或平台支付配置id
      currencySymbol: '$',
      country: 'US', // 国家
      currencyname: 'usd', // 货币类型
      publicKey: '', // 支付通道的公钥
      disabled: false,
      stripePayRequest: null,
      isCreditcard: false,
      cardStyle: {
        base: {
          color: '#32325d',
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4'
          }
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a'
        }
      },
      creditcard: null,
      clientSecret: null,
      debitOrder: null,
      isDebitcard: false,
      isIdael: false,
      debitcard: null,
      payModeList: [],
      cardList: [],
      orderId: '',
      mifiOrderId: '',
      emailAddress: '',
      confirmType: 'preAuth',
      round: 5
    }
  },
  methods: {
    goRouter (path) {
      this.$router.push(path)
    },
    // 支付方式选中
    onPayMode (v, bool = false) {
      console.log('支付方式===', v)
      if (bool) {
        this.payMode = v
      } else {
        this.pId = v.pId
        this.payMode = v.pPaymentType
        this.currencySymbol = v.currencySymbol
        this.currencyname = v.pCurrencyType
        this.publicKey = v.publicKey
        this.country = v.configDetails.accountCountry
      }
    },
    getStripeToken () {
      getToken(this.price, 'MY', this.currencyname.toLowerCase(), this.onStripe)
        .then(data => {
          console.log('stripe getToken', data)
          this.stripePayRequest = data
          this.$loading(false)
        })
        .catch(error => {
          this.stripePayRequest = null
          console.log('stripe getTokenerr', error)
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18n.msg_fail_support,
            showCancel: false,
            confirmText: this.i18n.msg_fail_confirm
          })
        })
    },
    // apple和Google支付时调用
    onStripe (token, ev) {
      let url = ''
      let datas = {}
      let headers = {}
      if (this.cabinet && this.payType === 'YJ') {
        url = STRIPE_PREPAY_SPTOKEN
        datas = {
          qrcode: this.cabinet.qrcode,
          stripeToken: token, // stripe官方支付的一次性token
          paymentId: this.pId // 代理或平台支付配置id
        }
      } else if (this.payType === 'mifi') {
        url = MIFI_PREAUTHPAY
        datas = this.params
        headers = {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8'
          }
        }
        this.confirmType = 'preAuth'
      } else if (this.price > 0 && this.payType === 'FK') {
        url = STRIPE_PAYORDER_SPTOKEN
        datas = {
          outTradeNo: this.orderId,
          couponNo: null,
          stripeToken: token,
          paymentId: this.pId
        }
      } else {
        this.$confirm({
          title: this.i18nMsg.title,
          content: this.i18nMsg.error,
          confirmText: this.i18nMsg.confirm,
          showCancel: false
        }).then(() => {
          this.$router.push('/')
        })
      }
      this.$post(
        url,
        datas,
        resp => {
          if (ev && ev.complete) {
            ev.complete('success')
          }
          this.$loading(false)
          console.log(resp)
          if (this.payType === 'CZ') {
            this.$confirm({
              title: this.i18nMsg.title,
              content: this.i18nMsg.success_payment,
              confirmText: this.i18nMsg.confirm,
              showCancel: false
            }).then(() => {
              this.$router.replace('/mine')
            })
          } else {
            this.$toast(this.i18nMsg.success_payment)
            if (this.payType === 'FK') {
              this.$router.replace(`/borrowSuccess?tradeNo=${resp.data.orderId}&payMode=stripe&payType=FK`)
              return
            }
            if (this.payType === 'mifi') {
              this.clientSecret = resp.data
              this.confirmSetup(1, '')
              // this.$router.replace(`/borrowSuccess?tradeNo=${resp.data.orderId}&loading=${this.mifiOrder && !this.mifiOrder.xugou}`)
              return
            }
            this.$router.replace(`/borrowSuccess?tradeNo=${resp.data.orderId}&payMode=stripe`)
          }
        },
        error => {
          if (ev && ev.complete) {
            ev.complete('fail')
          }
          this.$loading(false)
          console.log(error)
          this.$confirm({
            title: this.i18nMsg.title,
            content: this.i18nMsg.error,
            confirmText: this.i18nMsg.confirm,
            showCancel: false
          })
        },
        headers
      )
    },
    onPay () {
      if (this.payMode === 'paypal') {
        this.onPaypal()
      } else if (this.payMode === 'APPLE_PAY' || this.payMode === 'STRIPE_APPLE' || this.payMode === 'STRIPE_GOOGLE' || this.payMode === 'GOOGLE_PAY') {
        this.stripePayRequest.show()
      } else if (this.payMode === 'STRIPE_CARD') {
        if (this.$route.query.mifiOrderId) {
          this.wakeUpPayment()
        } else {
          this.onCreditcard()
        }
      } else if (this.payMode === 'STRIPE_IDAEL') {
        this.stripeRechargeWallet()
      } else if (this.payMode === 'debitcard') {
        this.onDebitcard()
      } else if (this.payMode === 'STRIPE_WECHAT') {
        this.onWechatPay()
      } else if (typeof this.payMode === 'object') {
        console.log('卡支付', this.payMode)
        this.stripeCardPay()
      } else {
        this.$loading(true)
        setTimeout(() => {
          this.$loading(false)
          this.$router.replace('/borrowSuccess?loading=false')
        }, 3000)
      }
    },
    onPaypal () {
      this.$loading(true)
      let url = ''
      if (this.payType === 'CZ' || this.payType === 'mifi') {
        url = PAYPAL_RECHARGE
      } else if (this.cabinet) {
        url = `${PAYPAL_PRE_PAY}/${this.cabinet.qrcode}`
      }
      this.$post(
        url,
        {
          amount: this.price
        },
        resp => {
          this.$loading(false)
          if (resp.data) {
            this.$loading(true)
            window.location.href = resp.data
          }
        },
        error => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18n.msg_fail_order,
            confirmText: this.i18n.msg_fail_retry,
            cancelText: this.i18n.msg_fail_cancel
          }).then(() => {
            this.onPaypal()
          })
          console.log(error)
        }
      )
    },
    // mifi继续支付
    wakeUpPayment () {
      this.$loading(true)
      this.$get(
        `${MIFI_WAKEUPPAY}`,
        {
          paymentId: this.pId,
          mifiOrderId: this.mifiOrderId
        },
        resp => {
          this.$loading(false)
          console.log('数据===', resp)
          if (resp.data) {
            if (window.stripeElements) {
              this.clientSecret = resp.data
              this.loadStripeScript(this.publicKey)
              this.creditcard = window.stripeElements.create('card', { style: this.cardStyle })
              this.creditcard.mount('#credit-card-element')
              this.$nextTick(() => {
                this.isCreditcard = true
              })
            } else {
              this.loadStripeScript(this.publicKey)
              this.$toast('loading...')
            }
          }
        },
        error => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            // content: this.i18n.msg_fail_order,
            content: error,
            confirmText: this.i18n.msg_fail_retry,
            cancelText: this.i18n.msg_fail_cancel
          }).then(() => {
            this.wakeUpPayment()
          })
          console.log(error)
        }
      )
    },
    stripeCardPay () {
      this.$loading(true)
      this.$post(
        `${STRIPE_CARD_PAY}/${this.mificode}`,
        {
          cardId: this.payMode.pId
        },
        resp => {
          this.$loading(false)
          console.log('数据===', resp)
          if (resp.data && resp.data.outTradeNo) {
            if (this.payType === 'mifi') {
              this.$router.replace(`/borrowSuccess?tradeNo=${resp.data.outTradeNo}&payMode=stripe&loading=${this.mifiOrder && !this.mifiOrder.xugou}`)
            } else {
              this.$router.replace(`/borrowSuccess?tradeNo=${resp.data.outTradeNo}&payMode=stripe&loading=true`)
            }
          } else {
            this.$toast(resp.msg)
          }
        },
        error => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            // content: this.i18n.msg_fail_order,
            content: error,
            confirmText: this.i18n.msg_fail_retry,
            cancelText: this.i18n.msg_fail_cancel
          }).then(() => {
            this.stripeCardPay()
          })
          console.log(error)
        }
      )
    },
    onCreditcard () {
      let url = ''
      let datas = {}
      let headers = {}
      console.log('stripe弹窗===', this.cabinet, this.payType)
      if (this.cabinet && this.payType === 'YJ') {
        url = `${STRIPE_PREAUTH_OF_CONFIRM}/${this.cabinet.qrcode}`
        datas = {
          paymentId: this.pId,
          amount: this.price
        }
        this.confirmType = 'preAuth'
      } else if (this.price > 0 && this.payType === 'FK') {
        url = STRIPE_PAYORDER
        datas = {
          outTradeNo: this.orderId,
          couponNo: null,
          paymentId: this.pId
        }
        this.confirmType = 'czOrder'
      } else if (this.payType === 'mifi') {
        url = MIFI_PREAUTHPAY
        datas = this.params
        headers = {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8'
          }
        }
        this.confirmType = 'preAuth'
      } else {
        url = STRIPE_RECHARGE_WALLET
        datas = {
          amount: this.price,
          paymentId: this.pId
        }
        this.confirmType = 'czOrder'
      }
      this.$loading(true)
      this.$post(
        url,
        datas,
        resp => {
          this.$loading(false)
          console.log(resp)
          if (resp.data) {
            this.$store.commit('_clientSecret', resp.data)
            if (window.stripe) {
              const layout = {
                layout: 'tabs'
              }
              const options = {
                clientSecret: resp.data.clientSecret,
                appearance: {
                  theme: 'stripe'
                }
              }
              // this.loadStripeScript(this.publicKey)
              this.creditcard = window.stripe.elements(options)
              const paymentElement = this.creditcard.create('payment', layout)
              this.isCreditcard = true
              paymentElement.mount('#credit-card-element')

              // this.creditcard = window.stripeElements.create('card', { style: this.cardStyle })
              // this.creditcard.mount('#credit-card-element')
              // this.$nextTick(() => {
              //   this.isCreditcard = true
              // })
            } else {
              this.loadStripeScript(this.publicKey)
              this.$toast('loading...')
            }
          }
        },
        error => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            // content: this.i18n.msg_fail_order,
            content: error,
            confirmText: this.i18n.msg_fail_retry,
            cancelText: this.i18n.msg_fail_cancel
          }).then(() => {
            this.onCreditcard(this.publicKey)
          })
          console.log(error)
        },
        headers
      )
    },
    onStripeSetup () {
      const vm = this
      vm.$loading(true)
      // 租借
      window.stripe
        .confirmPayment({
          elements: vm.creditcard,
          confirmParams: {
            return_url: window.location.href
          }
        })
        .then(result => {
          console.log(result)
          vm.$loading(false)
          if (result.error) {
            vm.$toast(result.error.message)
          }
        })
    },
    confirmSetup (id, success) {
      this.$loading(true)
      let url = `${STRIPE_PREAUTH_AUTO_CONFIRM}?tradeNo=${id}`
      let datas = {}
      if (this.payType === 'mifi') {
        url = MIFI_PREAUTHCONFIRM
        datas = {
          // outTradeNo: this.clientSecret.outTradeNo,
          isPopUp: this.mifiOrder && this.mifiOrder.xugou ? 0 : 1
        }
      }
      this.$get(
        url,
        datas,
        resp => {
          this.$loading(false)
          this.isCreditcard = false
          if (success && success === 'true') {
            this.useBalanceToRent()
            return
          }
          if (this.payType === 'mifi') {
            this.$router.replace(`/borrowSuccess?tradeNo=${resp.data.orderId}&loading=${this.mifiOrder && !this.mifiOrder.xugou}`)
            return
          } else if (this.payType === 'CZ') {
            if (resp.data.orderStatus === 0 && this.round >= 0) {
              this.confirmSetup(id, success)
              this.round--
            } else {
              this.round = 5
              this.$router.replace(`/wallet`)
            }
            return
          } else if (this.payType === 'FK') {
            this.$router.replace(`/borrowSuccess?tradeNo=${resp.data.orderId}&payMode=stripe&payType=${this.payType}`)
            return
          }
          this.$router.replace(`/borrowSuccess?tradeNo=${resp.data.orderId}&payMode=stripe&loading=true`)
          console.log(resp)
        },
        error => {
          this.$loading(false)
          console.log(error)
        }
      )
    },
    // stripe充值余额租借
    stripeRechargeWallet () {
      this.$loading(true)
      this.$post(
        STRIPE_RECHARGE_WALLET,
        {
          amount: this.price,
          paymentId: this.pId
        },
        resp => {
          this.$loading(false)
          this.clientSecret = resp.data
          this.confirmType = 'czOrder'
          if (resp.data) {
            if (window.stripeElements) {
              this.loadStripeScript(this.publicKey)
              this.creditcard = window.stripe.elements({ appearance: { theme: 'stripe' }, clientSecret: resp.data.clientSecret })
              const linkAuthenticationElement = this.creditcard.create('linkAuthentication')
              linkAuthenticationElement.mount('#link-authentication-element')
              linkAuthenticationElement.on('change', event => {
                this.emailAddress = event.value.email
              })
              const paymentElement = this.creditcard.create('payment', { layout: 'tabs' })
              paymentElement.mount('#payment-element')
              this.$nextTick(() => {
                this.isIdael = true
              })
            } else {
              this.loadStripeScript(this.publicKey)
              this.$toast('loading...')
            }
          }
          // this.confirmSetup(resp.data.tradeNo, '', 'czOrder')
          // this.$router.replace(`/borrowSuccess?tradeNo=${resp.data.orderId}&payMode=stripe`)
          console.log(resp)
        },
        error => {
          this.$loading(false)
          console.log(error)
        }
      )
    },
    onIdaelPay () {
      this.$loading(true)
      let vm = this
      let isparam = window.location.href.indexOf('?') !== -1
      window.stripe
        .confirmPayment({
          elements: vm.creditcard,
          confirmParams: {
            // Make sure to change this to your payment completion page
            return_url: `${window.location.href}${isparam ? '&' : '?'}orderNo=${this.clientSecret.outTradeNo}`,
            receipt_email: vm.emailAddress
          }
        })
        .then(result => {
          console.log(result)
          vm.$loading(false)
          if (result.error) {
            vm.$toast(result.error.message)
          }
          // else {
          //   vm.confirmSetup(result.paymentIntent.id, result.paymentIntent.payment_method)
          // }
        })
    },
    onStripePay () {
      const vm = this
      vm.$loading(true)
      window.stripe.handleCardPayment(vm.debitOrder.clientSecret, vm.debitcard).then(result => {
        console.log('result', result)
        if (result.error) {
          vm.$loading(false)
          vm.$toast(result.error.message)
        } else {
          window.stripe.retrievePaymentIntent(vm.debitOrder.clientSecret).then(result => {
            console.log('init result', result)
            vm.$loading(false)
            if (result.paymentIntent) {
              vm.confirmPay(result.paymentIntent.id)
            }
          })
        }
      })
    },
    confirmPay (id) {
      this.$loading(true)
      this.$post(
        STRIPE_PAY_CONFIRM,
        {
          paymentId: id,
          outTradeNo: this.debitOrder.outTradeNo
        },
        resp => {
          this.$loading(false)
          this.isCreditcard = false
          if (this.payType === 'CZ') {
            this.$router.go(-2)
          } else if (this.cabinet) {
            this.$router.replace(`/borrowSuccess?tradeNo=${resp.data}&payMode=stripe`)
          }
          console.log(resp)
        },
        error => {
          this.$loading(false)
          console.log(error)
        }
      )
    },
    onDebitcard () {
      this.$loading(true)
      let url = ''
      if (this.payType === 'CZ') {
        url = STRIPE_RECHARGE_OF_CONFIRM
      } else if (this.cabinet) {
        url = `${STRIPE_PAY_OF_CONFIRM}/${this.cabinet.qrcode}`
      }
      this.$post(
        url,
        {
          amount: this.price
        },
        resp => {
          this.$loading(false)
          console.log(resp)
          if (resp.data) {
            if (window.stripeElements) {
              this.debitOrder = resp.data
              this.loadStripeScript(this.publicKey)
              this.debitcard = window.stripeElements.create('card', { style: this.cardStyle })
              this.debitcard.mount('#debit-card-element')
              this.$nextTick(() => {
                this.isDebitcard = true
              })
            } else {
              this.$toast('loading...')
            }
          }
        },
        error => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18n.msg_fail_order,
            confirmText: this.i18n.msg_fail_retry,
            cancelText: this.i18n.msg_fail_cancel
          }).then(() => {
            this.onDebitcard(this.publicKey)
          })
          console.log(error)
        }
      )
    },
    onWechatPay () {
      this.$loading(true)
      const vm = this
      this.$post(
        `${WECHAT_MP_PRE_PAY}/${this.cabinet.qrcode}`,
        {
          amount: this.price,
          formId: this.formId || ''
        },
        resp => {
          this.$loading(false)
          console.log('wechat pre pay', resp)
          const data = resp.data
          wx.chooseWXPay({
            timestamp: data.timeStamp,
            nonceStr: data.nonceStr,
            package: data.package,
            signType: data.signType,
            paySign: data.paySign,
            success: function (res) {
              if (vm.payType === 'mifi') {
                this.$router.replace(`/borrowSuccess?tradeNo=${data.out_trade_no}&loading=${this.mifiOrder && !this.mifiOrder.xugou}`)
                return
              }
              vm.$router.replace(`/borrowSuccess?tradeNo=${data.out_trade_no}&shopid=${this.cabinet.shopid}`)
            },
            fail: function (res) {
              console.log('wxpay fail', res)
              vm.$toast(res.errMsg)
            }
          })
        },
        error => {
          console.log('wechat pre pay error', error)
          this.$loading(false)
          this.$toast(error)
        }
      )
    },
    // 押金下单
    onMifiDeposit () {
      console.log('下单===', this.mifiOrder)
      this.$loading(true)
      this.$post(
        MIFI_RENT,
        this.params,
        resp => {
          console.log('下单===', resp)
          this.$router.replace(`/borrowSuccess?tradeNo=${resp.data.orderId}&loading=${this.mifiOrder && !this.mifiOrder.xugou}`)
        },
        error => {
          this.$loading(false)
          this.$toast(error)
          console.log(error)
        }
      )
    },
    // 用户钱包余额租借
    useBalanceToRent () {
      this.$loading(true)
      this.$get(
        ORDER_TEMP,
        {
          qrcode: this.$route.query.cabinetID
        },
        resp => {
          this.$loading(false)
          console.log('钱包余额租借===', resp)
          this.$router.replace(`/borrowSuccess?tradeNo=${resp.data.outTradNo}&loading=true`)
        },
        error => {
          this.$loading(false)
          this.$toast(error)
          console.log(error)
        }
      )
    },
    getPayMode () {
      console.log('hahah', this.cabinet, this.wallet)
      let url = this.payType !== 'CZ' ? `${PAYMODE}${`?id=${this.qrcode || this.$route.query.cabinetID}`}` : `${PAYMODE}?agentId=${this.wallet ? this.wallet.pDailiId : this.agentOpenId}`
      if (this.payType === 'mifi') {
        if (this.mificode && this.mificode.indexOf('http') !== -1) {
          url = `${PAYMODE}?url=${encodeURIComponent(this.mificode)}`
        } else if (this.mifiOrder && this.mifiOrder.payType === 'GM') {
          url = `${PAYMODE}?currency=${this.mifiOrder.currency}`
        } else if (this.mifiOrder && this.mifiOrder.xugou) {
          url = `${PAYMODE}?sn=${this.mifiOrder.productSendSn}`
        } else {
          url = `${PAYMODE}?id=${this.mificode}`
        }
      }
      this.$loading(true)
      this.$get(
        url,
        {},
        resp => {
          this.$loading(false)
          console.log(resp)
          if (resp.data) {
            let list = resp.data
            list.map((v, i, arr) => {
              if (v.pPaymentType === 'STRIPE_CARD') {
                v.label = 'Credit Card'
                v.phone = 'all'
              } else if (v.pPaymentType === 'STRIPE_WECHAT') {
                v.label = 'WeChat'
                v.phone = 'wechat'
              } else if ((v.pPaymentType === 'APPLE_PAY' || v.pPaymentType === 'STRIPE_APPLE') && this.payType !== 'mifi') {
                v.label = 'Apple Pay'
                v.phone = 'ios'
              } else if ((v.pPaymentType === 'GOOGLE_PAY' || v.pPaymentType === 'STRIPE_GOOGLE') && this.payType !== 'mifi') {
                v.label = 'Google Pay'
                v.phone = 'android'
              } else if (v.pPaymentType === 'STRIPE_IDAEL') {
                v.label = 'Idael Pay'
                v.phone = 'all'
              } else {
                v.label = 'Unknow'
                v.phone = 'null'
              }
              // list.push(v)
            })
            this.payModeList = list
            if (list.length > 0) {
              let item = list.filter(v => {
                return v.pPaymentType === 'STRIPE_CARD'
              })
              this.payMode = item.length > 0 ? item[0].pPaymentType : list[0].pPaymentType
              this.currencySymbol = item.length > 0 ? item[0].currencySymbol : list[0].currencySymbol // 货币符号
              this.currencyname = item.length > 0 ? item[0].configDetails.currency : list[0].configDetails.currency // 货币类型
              this.publicKey = item.length > 0 ? item[0].publicKey : list[0].publicKey
              this.pId = item.length > 0 ? item[0].pId : list[0].pId
              this.country = item.length > 0 ? item[0].configDetails.accountCountry : list[0].configDetails.accountCountry
              console.log(this.publicKey, this.payMode)
              this.loadStripeScript(this.publicKey)
            }
            console.log('list======', list)
          }
        },
        error => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: error,
            confirmText: this.i18n.msg_fail_retry,
            cancelText: this.i18n.msg_fail_cancel
          }).then(() => {
            this.getPayMode()
          })
          console.log(error)
        },
        {
          headers: {
            agentOpenId: this.agentOpenId
            // agentOpenId: this.mifiOrder && this.mifiOrder.xugou ? this.agentOpenId : ''
          }
        }
      )
    },
    getUserCardList () {
      let url = ''
      if (this.payType === 'CZ') {
        url = `${USER_CARD}?userEnd=web${this.walletItem ? `&agentId=${this.wallet.pDailiId}` : ``}`
      } else if ((this.payType === 'mifi' && this.mificode && this.mificode.indexOf('http') === -1) || this.payType === 'YJ') {
        url = `${USER_CARD}?userEnd=web&qrcode=${this.mificode}`
      } else {
        url = `${USER_CARD}?userEnd=web&agentId=${this.agentOpenId}`
      }
      this.$loading(true)
      this.$get(
        url,
        {},
        resp => {
          this.$loading(false)
          console.log(resp)
          this.cardList = resp.data
        },
        error => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18nMsg.error,
            confirmText: this.i18nMsg.retry,
            cancelText: this.i18nMsg.cancel
          }).then(() => {
            this.getUserCardList()
          })
          console.log(error)
        },
        {
          headers: {
            agentOpenId: this.agentOpenId
          }
        }
      )
    }
  },
  created () {
    let price = this.$route.query.price
    let formId = this.$route.query.formId
    this.price = price ? Number(price) : 0
    this.orderId = this.$route.query.outTradeNo
    this.mifiOrderId = this.$route.query.mifiOrderId
    this.formId = formId
    this.payType = this.$route.query.payType
    this.cabinetID = this.$route.query.cabinetID
    this.yaJinType = this.$route.query.yaJinType
    this.getPayMode()
    // if (this.payType === 'YJ') {
    //   this.getUserCardList()
    // }
    let isPaymentIntent = getQueryString('payment_intent')
    let redirectStatus = getQueryString('redirect_status')
    if (isPaymentIntent && redirectStatus === 'succeeded' && this.payType === 'CZ' && this.cabinetID) { // 充值
      this.clientSecret = {
        outTradeNo: this.$route.query.orderNo
      }
      this.confirmType = 'czOrder'
      this.confirmSetup(isPaymentIntent, 'true')
    } else if (isPaymentIntent) { // 新版预授权
      console.log('test==', this._clientSecret)

      this.clientSecret = {
        outTradeNo: this._clientSecret.outTradeNo
      }
      this.confirmSetup(isPaymentIntent, '')
    }
    console.log(this)
  },
  beforeDestroy () {
    let reg = new RegExp(/([?]).*?([#/])/)
    // window.location.href = window.location.href.replace(reg, '#')
    window.history.replaceState(null, null, window.location.href.replace(reg, '#'))
    console.log('替换后地址===', window.location.href)
    console.log('beforeDestroy: 组件实例销毁之前')
  }
}
</script>

<style lang="less">
#form-checkout {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  .container {
    height: 50px;
    display: inline-block;
    border: 1px solid rgb(118, 118, 118);
    border-radius: 2px;
    padding: 1px 2px;
    margin-bottom: 10px;
  }
  &>select{
    height: 60px;
    margin-bottom: 10px;
  }
  &>input{
    height: 50px;
    margin-bottom: 10px;
  }
  &>button{
    background: #65b74e;
    color: #ffffff;
    border: none;
    outline: none;
    padding: 20px 0;
    margin-top: 20px;
  }
  .progress-bar{
    width: 100%;
  }
}

.noClick {
  opacity: 0.5;
  pointer-events: none; /* 禁止鼠标点击 */
}
.confirm-warp {
  overflow-y: scroll;
}
.confirm-info {
  background: #ffffff;
  text-align: center;
  padding: 40px;
}
.confirm-title {
  font-size: 36px;
}
.confirm-price {
  font-size: 80px;
  color: #fc0d1b;
}
.confirm-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  & img {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
  }
  & span {
    text-align: left;
    font-size: 28px;
    color: #999999;
  }
}
.confirm-payment {
  padding: 30px;
  &-label {
    padding: 10px 20px;
    font-size: 28px;
    color: #999999;
  }
  &-item {
    background: #ffffff;
    display: flex;
    align-items: center;
    padding: 30px 20px;
    margin-bottom: 20px;
    border-radius: 18px;
    box-shadow: 0px 6px 10px 1px rgba(0,0,0,0.05);
    // border-bottom: 1px solid #dddddd;
    & img {
      width: 40px;
      height: 40px;
    }
    &-info {
      display: flex;
      align-items: center;
      flex: 1;
      & .content {
        padding-left: 10px;
      }
      & .sub_title {
        font-size: 28px;
        color: #fec348;
      }
    }
  }
}
.confirm-remark {
  padding: 0 32px;
}
.confirm-remark .title {
  margin-top: 40px;
  font-size: 32px;
}
.confirm-remark .desc {
  font-size: 28px;
  color: rgba(102, 102, 102, 1);
  font-weight: 400;
  word-break: normal !important;
  white-space: normal !important;
}
.confirm-btns {
  padding: 60px 40px 20px;
}
.confirm-btns .btn {
  margin-top: 20px;
}
/* #ifdef MP-WEIXIN */
.confirm-btns button.btn {
  background-color: #65b74e;
}
/* #endif */
.confirm-checkbox {
  padding: 30px;
  & label {
    display: flex;
    align-items: center;
  }
  & span {
    color: #52ac7a;
  }
}
.btn-paypal {
  background: #fec348 !important;
}
.btn-paypal img {
  width: 277px;
  height: 60px;
  margin: 18px 0;
}
.btn-applepay {
  background: #ffffff !important;
  color: #050608;
}
.btn-applepay img {
  width: 143px;
  height: 60px;
  margin: 18px 0;
}
.btn-googlepay {
  color: #5f6368;
  background: #ffffff !important;
}
.btn-googlepay img {
  width: 145px;
  height: 60px;
  margin: 18px 0;
}
.creditcard-input {
  margin: 48px 48px 100px;
  padding: 20px;
  border-radius: 20px;
  border: 1px solid #e4e7ed;
}
.creditcard-btn {
  margin: 48px;
}
.confirm-row {
  margin: 36px 45px 140px;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 4px solid rgb(211, 211, 211);
  border-radius: 20px;
  .area {
    width: 100px;
    padding: 0 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  input {
    font-size: 32px;
    width: 300px;
    flex: 1;
    height: 80px;
    padding: 0 20px;
    margin: 0;
    border: none;
    outline: none;
    background-color: rgba(0, 0, 0, 0);
  }
  .area-down {
    // margin-left: 10px;
    width: 0;
    height: 0;
    border-width: 10px 10px 0;
    border-style: solid;
    border-color: black transparent transparent;
  }
  .login-code {
    width: 180px;
    height: 60px;
    background-image: linear-gradient(to right, rgba(194, 194, 194, 1), rgba(252, 252, 252, 1));
    img {
      width: 180px;
      height: 60px;
    }
  }
}
.pay-amount {
  padding: 43px 20px;
  background: #ffffff;
  border-radius: 10px 10px 10px 10px;
  margin-top: 40px;

  .pay-amount-title {
    font-size: 30px;
    font-family: Work Sans-Medium, Work Sans;
    font-weight: 500;
    color: #444444;
  }

  .doller {
    font-size: 30px;
    font-family: Work Sans-Regular, Work Sans;
  }

  .pay-price {
    font-size: 54px;
    font-family: Work Sans-Bold, Work Sans;
    font-weight: bold;
    padding: 0 10px;
  }
}

.pay-h1 {
  font-size: 32px;
  font-family: Work Sans-Bold, Work Sans;
  font-weight: bold;
  color: #333333;
  padding: 40px 40px 20px 40px;
}
</style>
