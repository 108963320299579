<template>
  <page title="Past Order">
    <template v-if="items.length > 0">
      <div class="vcard scan-item" v-for="(item) in items" :key="item.pOrderid" @click="goOrderDetail(item)">
        <div class="flex-s past-header">
          <div>
            <span class="flex-c">
              <van-image v-if="item.deviceLabel==i18nSeletPakge.powerbank" class="device-img" width="0.68rem" height="0.8rem" fit="contain"
            :src="require('../../assets/imgs/icon_power_box.png')" />
            <van-image v-if="item.deviceLabel==i18nSeletPakge.wifibox" class="device-img" width="0.68rem" height="0.8rem" fit="contain"
            :src="require('../../assets/imgs/icon_wifi.png')" />
              <span class="device-label">{{item.deviceLabel}}</span>
            </span>
          </div>
          <div class="device-type">{{item.pTypeName}}</div>
        </div>
        <div class="past-line"></div>
        <div class="flex-s order-box">
          <div class="ordert-title">{{i18nOd.orderNo}}</div>
          <div class="ordert-value">
            <span>{{ item.porderid }}</span>
            <img src="../../assets/imgs/order_icon_copy@2x.png" @click.stop="copy(item.porderid, $event)">
          </div>
        </div>
        <!-- <van-cell :title="i18nOd.orderNo" :value="item.porderid">
          <div style="display: flex;justify-content: flex-end;align-items: center;">
            <span>{{item.porderid}}</span>
          </div>
          <div v-solt:extra>
            <van-icon :name="require('assets/imgs/order_icon_copy@2x.png')" size="0.58rem"></van-icon>
          </div>
        </van-cell> -->
        <van-cell :title="i18nOd.LeaseMerchant" :value="item.pShopName" />
        <van-cell :title="i18nOd.ReturningMerchants" :value="item.pGhShopName" />
        <div class='price-time flex-s'>
          <div class="time">{{item.pUseMins}}  {{i18nOrderItem.min}}</div>
          <div>{{item.pBorrowtime}}</div>
          <div class="time">{{item.pCurrencyName}} {{item.pJiesuan}}</div>
        </div>
      </div>
    </template>
    <result-msg v-else type="500" :text="i18nMsg.nodata" @click="onReload" />
  </page>
</template>

<script>
import { ORDER_LIST } from '../../apis/user'
import ResultMsg from '@/components/result/msg.vue'
import Cipboard from 'clipboard'
export default {
  components: {
    ResultMsg
  },
  computed: {
    i18n () {
      return this.$t('pastOrder')
    },
    i18nOrderItem () {
      return this.$t('order_item')
    },
    i18nOd () {
      return this.$t('orderDetail')
    },
    i18nMsg () {
      return this.$t('msg')
    },
    i18nSeletPakge () {
      return this.$t('selectpackage')
    },
    borrowItems () {
      return this.items.filter(item => {
        return item.pJiesuanid === 0 && (item.pZujie === 1 || item.pZujie === 4)
      })
    },
    finishItems () {
      return this.items.filter(item => {
        return (item.pZujie !== 1 && item.pZujie !== 4) || item.pJiesuanid === 1
      })
    }
  },
  data () {
    return {
      items: [],
      error: ''
    }
  },
  methods: {
    onReload () {
      this.loadList()
    },
    copy (val, event) {
      console.log(val, Cipboard)
      let clip = new Cipboard(event.target, {
        text: () => val
      })
      clip.onClick(event)
      this.$toast(this.i18nMsg.copysuccess)
    },
    goOrderDetail (item) {
      this.$store.commit('zujieOrder', item)
      // if (item.pZujie !== 3) {
      // }
      this.$router.push(`/orderDetail?orderId=${item.pOrderid}`)
    },
    loadList (page = 1) {
      this.$loading(true)
      this.$get(
        ORDER_LIST,
        {
          page,
          pageSize: 10
        },
        resp => {
          this.$loading(false)
          if (resp.page) {
            this.error = ''
            this.items = resp.page.records.map(item => {
              item.deviceLabel = item.pType === 'chargeNowMifi' ? this.i18nSeletPakge.wifibox : item.pOrderid.indexOf('EN') !== -1 ? this.i18nSeletPakge.powerstation : this.i18nSeletPakge.powerbank
              // 已结束
              if ((item.pZujie !== 1 && item.pZujie !== 4) || item.pJiesuanid === 1) {
                item.pTypeName = 'End'
              }
              // 租借中
              if (item.pJiesuanid === 0 && (item.pZujie === 1 || item.pZujie === 4)) {
                item.pTypeName = 'Under lease'
              }
              return item
            })
          } else {
            this.error = this.i18nMsg.error
          }
        },
        error => {
          this.$loading(false)
          this.error = error
        }
      )
    }
  },
  created () {
    this.loadList()
  }
}
</script>

<style lang="less" scoped>
.scan-item{
  margin-bottom: 20px;
  padding: 10px;
}
.order-box{
    margin: 20px 30px 0;
    padding: 0 0 20px;
    border-bottom: solid 1px #ebedf0;
    img{
      width: 50px;
    }
    .ordert-title{
      color: #999;
    }
    .ordert-value{
      display: flex;
      align-items: center;
      span{
        margin-right: 10px;
      }
    }
  }
  .p-20{
    padding: 0 20px;
  }
  .p-v-20{
    padding: 20px ;
  }
  .device-label{
    font-size: 28px;
    font-family: Montserrat-Bold, Montserrat;
    font-weight: 600;
    color: #1A2229;
    margin-left: 10px;
  }
  .past-header{
    // padding: 24px;
  }
  .device-type{
    font-size: 24px;
    font-family: Work Sans-Medium, Work Sans;
    color: #21DA8C;
  }
  .past-line{
    width: 630px;
    height: 0px;
    opacity: 1;
    border: 2px solid #F4F4F4;
    margin: 5px 0;
  }

.price-time{
  background: #21DA8C;
  border-radius: 0 0 18px 18px;
  opacity: 1;
  color: #fff;
  padding:38px 20px;
  margin: -10px;
  margin-top: 10px;
  text-align: center;
  font-size: 22px;
  font-family: Work Sans-Light Italic, Work Sans;
}
.time{
  font-size: 40px;
  font-family: Work Sans-Bold, Work Sans;
  font-weight: bold;
}
</style>
