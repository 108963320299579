<template>
<div class="order-select-box1 order-box" >
        <van-image class="order-img" radius="12" :width="`${width}rem`" height="2.6rem" fit="contain" :src="src" />
        <div :class="useDay?'':'order-select-box2'" style="width: 5rem;">
          <div class="order-left-title" v-if="country">{{ country }}</div>
          <div class="order-left-title">{{ text }}</div>
          <div class="order-price" v-if="price">{{ currencyName }} {{ price }}</div>
          <div class="order-deposit" v-if="deposit">{{ i18n.depositof }}:{{ deposit }}</div>
          <div class="order-info flex-s" v-if="useDay">
            <span>{{i18n.useDay}}: {{ useDay }} {{i18n.days}}</span>
            <slot></slot>
          </div>
        </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    src: {
      type: String,
      default: require('../../assets/imgs/img_orderDetail.png')
    },
    text: {
      type: String,
      default: ''
    },
    price: {
      type: String,
      default: ''
    },
    country: {
      type: String,
      default: ''
    },
    currency: {
      type: String,
      default: '$'
    },
    currencyName: {
      type: String,
      default: '$'
    },
    useDay: {
      type: Number || String || undefined,
      default: 0
    },
    width: {
      type: Number || String || undefined,
      default: 3.5
    },
    deposit: {
      type: Number || String,
      default: 0
    }
  },
  computed: {
    i18n () {
      return this.$t('wifimall')
    }
  },
  methods: {

  }
}
</script>

<style lang="less" >
.order-select-box1{
  display: flex;
  align-items: flex-start;
  // height: 2.59rem;
}
.order-select-box2{
  display:flex;
  flex-flow: column; //垂直排列
  justify-content: space-around;//两端对齐
  height: 2.59rem;
}
.order-box{
  padding: 20px;
  position: relative;
  .order-img{
    margin-right: 20px;
    border: 12px;
  }
  .order-left-title{
    font-size: 30px;
    font-weight: 500;
    color: #444444;
    line-height: 36px;
  }
  .order-price{
    font-size: 36px;
    font-weight: bold;
    color: #FF1919;
    line-height: 36px;
    margin: 20px 0;
  }
  .order-deposit{
    color: #909090;
  }
  .order-info{
    font-size: 20px;
    font-weight: 400;
    color: #909090;
    line-height: 36px;
  }
}
</style>
