<template>
  <page title="Package Order">
    <div class="vcard" style="padding: 0;">
      <OrderItem :src="mifiPackage && mifiPackage.flagUrl" :text="mifiPackage && (mifiPackage.country + '-' + mifiPackage.text)" :price="mifiPackage.price" :currencyName="mifiPackage.currencyName" >
        <span class="package-order-num">x1</span>
      </OrderItem>
      <van-cell :title="i18nPgeDetail.startingTime" :value="startTime" />
      <van-cell :title="i18nPgeDetail.endTime" :value="endTime" />
    </div>
    <div v-if="type === 'GM'" class="vcard order-page">
      <div class="device-title">
        <span>{{ i18n.isrent }}</span>
        <van-icon v-if="isCheck" :name="require('../../assets/imgs/icon_select.png')" size="0.6rem" @click="isCheck = false"/>
        <van-icon v-else :name="require('../../assets/imgs/icon_unselect.png')" size="0.6rem"  @click="isCheck = true"/>
      </div>
      <div class="flex-s">
        <van-image class="device-img" width="1.68rem"  fit="contain"
          :src="require('@/assets/imgs/device.png')" />
        <div>
          <div class="device-name">{{ mifiData && mifiData.typeName }}</div>
          <div>
            <span class="device-price">{{ mifiData && mifiData.currencyName }}{{ mifiData && mifiData.depositAmount }}</span>
            <span class="device-info">{{ i18n.timelimited }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-s submit-bar">
      <div>
        <div class="bar-price">{{ mifiPackage && mifiPackage.currencyName }}{{ price }}</div>
        <!-- <div class="bar-detail">{{ i18n.viewdetail }}</div> -->
      </div>
      <van-button @click="onSubmit" class="bottom-pakage-btn" round >{{ i18n.buynow }}</van-button>
    </div>
  </page>
</template>

<script>
import { mapState } from 'vuex'
import OrderItem from '../../components/orderItem/orderItem.vue'
import { MIFI_DEVICEDEPOSIT } from '../../apis/cabinet'
import dayjs from 'dayjs'
export default {
  components: {
    OrderItem
  },
  computed: {
    ...mapState(['mificode', 'mifisn', 'qrcode', 'cabinet', 'mifiPackage']),
    i18n () {
      return this.$t('packageOrder')
    },
    i18nPgeDetail () {
      return this.$t('packageDetail')
    },
    price () {
      let m = 0
      if (this.type === 'GM' && this.mifiData && this.isCheck) {
        m += Number(this.mifiData.depositAmount) + Number(this.mifiPackage.price)
        console.log(Number(this.mifiData.depositAmount), Number(this.mifiPackage.price))
      } else {
        m += Number(this.mifiPackage.price)
      }
      return m.toFixed(2)
    }
  },
  data () {
    return {
      type: 'GM',
      startTime: '',
      endTime: '',
      mifiData: null,
      isCheck: true
    }
  },
  methods: {
    onSubmit () {
      console.log('续购按钮')
      let order = {
      }
      if (this.mifiPackage) {
        order = {
          ...order,
          ...this.mifiPackage
        }
      }
      if (this.type === 'GM') {
        if (this.isCheck) {
          order.productId = this.mifiData.id
          order.depositAmount = this.mifiData.depositAmount
        }
        order.payType = 'GM'
        order.xugou = true
      } else {
        order.productSendSn = this.mifisn
        order.xugou = true
      }
      order.startTime = this.startTime
      order.endTime = this.endTime
      this.$store.commit('mifiOrder', order)
      this.$router.push({ path: '/confirm', query: { price: this.price, payType: 'mifi' } })
    },
    getMifiData () {
      this.$loading(true)
      this.$get(
        `${MIFI_DEVICEDEPOSIT}`,
        {},
        resp => {
          this.$loading(false)
          this.mifiData = resp.data
          console.log('数据===', this.mifiData)
        },
        error => {
          this.$loading(false)
          this.$toast(error)
        }
      )
    }
  },
  created () {
    if (this.$route.query.type) {
      this.type = this.$route.query.type
    }
    this.getMifiData()
    this.startTime = dayjs(this.mifiPackage.myStartime).format('YYYY-MM-DD HH:mm:ss')
    this.endTime = dayjs(this.startTime).add(this.mifiPackage.selectDays, 'day').format('YYYY-MM-DD HH:mm:ss')
  }
}
</script>

<style lang="less" scoped>
.package-order-num{
  position: absolute;
    right: 40px;
    bottom: 40px;
}
.order-page{
margin-top: 20px;
  .device-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 30px;
    font-weight: bold;
    color: #333333;
    margin: 10px 0;
  }
  .device-img{
    margin-right: 10px;
  }
  .device-name{
    font-size: 28px;
    font-weight: 500;
    color: #333333;
  }
  .device-price{
    font-size: 36px;
    font-weight: bold;
    color: #FF1919;
    line-height: 2;
    margin-right: 20px;
  }
  .device-info{
    font-size: 22px;
    font-weight: 400;
    color: #21B97C;
  }
}
  .bottom-pakage-btn{
background: #21DA8C;
box-shadow: 0px 6px 10px 1px rgba(0,171,70,0.2);
border-radius: 60px 60px 60px 60px;
padding: 23px 73px;
color: #fff ;
  }
  .submit-bar{
    position: fixed;
    bottom: 68px;
    margin: 0 40px;
    width: 90%;
    .bar-price{
      font-size: 40px;
      font-weight: bold;
      color: #FF1919;
      padding-bottom: 10px;
    }
    .bar-detail{
background: #F5DDDD;
border-radius: 20px 20px 20px 20px;
font-size: 21px;
color: #FF1919;
line-height: 34px;
text-align: center;
    }
  }
  .p-20{
    padding: 0 20px;
  }
  .p-v-20{
    margin: 20px;
  }
</style>
