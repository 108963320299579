<template>
  <page title="Usage">
    <div class="Usage" ref="Usage">
      <template v-for="(item,index) in  usageList" >

        <div class="vcard usage-box" :key="item.info" :ref="`step_${index}`">
        <div class="u-step flex-c">step {{index+1}}</div>
        <div class="u-img">
          <van-image width="8rem" fit="contain" :src="item.src"></van-image>
        </div>
        <div class="u-info">{{item.info}}</div>
        <div class="flex-c dotted-box">
          <div :class="index==0?'dotted':'dotte'"></div>
          <div :class="index==1?'dotted':'dotte'"></div>
          <div :class="index==2?'dotted':'dotte'"></div>
          <div :class="index==3?'dotted':'dotte'"></div>

        </div>
      </div>
          </template>
    </div>
    <div class="next flex-c">
      <van-image width="1.17rem" fit="contain" :src="require('@/assets/imgs/icon_next@2x.png')" @click="nextImage"></van-image>
    </div>
  </page>
</template>

<script>

export default {
  components: {
  },
  computed: {

  },
  data () {
    return {
      usageList: [{
        src: require('../../assets/imgs/01.png'),
        info: 'Long press the power of the device to turn on the device Scan the QR code on the back with your cell phone'
      },
      {
        src: require('../../assets/imgs/02.png'),
        info: 'Go to Equipment Details Select Renewal Purchase'
      },
      {
        src: require('../../assets/imgs/03.png'),
        info: 'Select the destination country. Choose the traffic package, pay and place the order'
      },
      {
        src: require('../../assets/imgs/04.png'),
        info: 'Turn on your cellular WiFi Connect to the WiFi on the back'
      }]
    }
  },
  methods: {
    nextImage () {
      let box = this.$refs.step_0
      console.log(box[0].offsetWidth)
      this.$refs.Usage.scrollLeft += box[0].offsetWidth + 20
    }
  },
  created () {
  }
}
</script>

<style lang="less" scoped>
.Usage{
  display: flex;
  overflow-y: scroll;
  width: 100%;

}
::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.usage-box{
margin-top: 100px;

position: relative;
margin-right: 0;
&:last-child{
margin-right: 40px;

}
}
.u-step{
  width: 191px;
height: 91px;
background: #21DA8C;
border-radius: 18px 0px 50px 0px;
color: #fff;
font-size: 36px;
font-family: Work Sans-Bold, Work Sans;
font-weight: bold;
position: absolute;
top: 0;
left: 0;
}

.u-img{
  margin-top: 100px;
}
.dotted{
  width: 10px;
height: 10px;
margin: 0 10px;
background: #21DA8C;
}
.dotte{
width: 10px;
height: 10px;
margin: 0 10px;
background: #D1D1D1;
}
.dotted-box{
  margin: 20PX 0 10PX 0;
}
.next{
margin: 90px 0;
}
.u-info{
font-size: 30px;
font-weight: 500;
color: #000000;
line-height: 40px;
}
</style>
