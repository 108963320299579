<template>
  <page title=" " :backHandel="true">
    <div v-if="second>0" class="result-loading-warp">
      <div class="result-loading circle">
        <circle-progress v-model="percent" />
        <span>{{secondFmt}}s</span>
      </div>
      <div class="result-loading-tips">
        {{i18nResult.msg_tips_loading}}
      </div>
    </div>
    <template v-else-if="!error">
      <div class="flex-c" style="margin-bottom: 40px;">
        <div class="success-box">
          <van-image  width="2.16rem"  fit="contain"
            :src="require('@/assets/imgs/icon_success.png')" />
          <div class="success-title">{{i18n.success}}</div>
        </div>
      </div>
      <div class="p-20" v-if="this.mifiOrder && !this.mifiOrder.payType && !isCdb">
        <van-button type="primary" round style="width:100%;" class="bottom-pakage-btn" @click="onSubmit"> {{i18n.renewalTraffic}}</van-button>
      </div>
      <div class="p-v-20">
        <van-button plain type="primary" round style="width:100%;" @click="goOrder"> {{i18n.viewOrder}}</van-button>
      </div>
        <div class="p-v-20">
        <van-button plain type="primary" round style="width:100%;" @click="goWhere"> {{i18n.HowUseDevice}}</van-button>
      </div>
    </template>
    <!-- <result-msg v-else-if="loading" type="100" :text="i18n.msg_result_loading" /> -->
    <result-msg v-else-if="error" type="500" :text="error" @click="onReload" />
    <result-msg v-else />
  </page>
</template>

<script>
import { mapState } from 'vuex'
import CircleProgress from '../../components/result/circle_progress.vue'
import ResultMsg from '../../components/result/msg.vue'
import { ORDER_CHECK } from '../../apis/cabinet.js'
let loadingTimer
export default {
  components: {
    CircleProgress,
    ResultMsg
  },
  computed: {
    ...mapState(['mifiOrder', 'mificode']),
    i18n () {
      return this.$t('borrowSuccess')
    },
    i18nResult () {
      return this.$t('result')
    },
    secondFmt () {
      return (this.second + 0.45).toFixed(0)
    },
    percent () {
      return (this.second % 30) * 3.334
    },
    isCdb () {
      return !this.$route.query.loading || this.$route.query.payMode
    },
    haveMifi () {
      return process.env.VUE_APP_HAVE_MIFI === '1'
    }
  },
  data () {
    return {
      second: 0,
      error: '',
      tradeNo: '',
      payType: ''
    }
  },
  methods: {
    goRouter (path) {
      this.$router.replace(path)
    },
    onReload () {
      this.checkOrder(this.tradeNo, this.$route.query.payMode || 'stripe', this.payType)
    },
    goOrder () {
      if (this.mifiOrder && this.mifiOrder.payType === 'GM') {
        this.$router.replace('/scanOrder')
      } else {
        this.$router.replace('/pastOrder')
      }
    },
    goWhere () {
      if (this.isCdb) {
        this.$router.replace('/cdbUsage')
      } else {
        this.$router.replace('/usage')
      }
    },
    startTimer () {
      if (this.second === 0) {
        loadingTimer = setInterval(() => {
          this.second += 0.1
        }, 100)
      }
    },
    stopTimer () {
      clearInterval(loadingTimer)
      this.second = 0
    },
    onSubmit () {
      console.log('续购按钮')
      if (this.mifiOrder && this.mifiOrder.productSendSn && this.haveMifi) {
        this.$store.commit('mifisn', this.mifiOrder.productSendSn)
        this.$router.push('/wifiMall?type=XG')
      } else {
        this.$router.push(`/packageDetail?orderId=${this.$route.query.tradeNo}`)
      }
    },
    checkOrder (tradeNo, payMode = 'stripe', payType = '') {
      this.startTimer()
      // if (payMode === 'mpesa') {
      //   this.onSMSCheckOrder(tradeNo, payMode, payType)
      //   return
      // }
      this.tradeNo = tradeNo
      this.loading = true
      this.$post(
        `${ORDER_CHECK}/${tradeNo}/${payMode}`,
        {},
        resp => {
          if (payType === 'FK') {
            this.$router.replace({ path: '/pastOrder' })
            return
          } else if (payType === 'CZ') {
            this.$router.replace({ path: '/wallet' })
            return
          }
          this.error = ''
          this.stopTimer()
          this.order = resp
        },
        error => {
          if (payType === 'FK') {
            setTimeout(() => {
              this.$router.replace({ path: '/pastOrder' })
            }, 1500)
          } else if (payType === 'CZ') {
            setTimeout(() => {
              this.$router.replace({ path: '/wallet' })
            }, 1500)
          } else if (error === '正在请求中' || error === 'Processing') {
            setTimeout(() => {
              this.checkOrder(tradeNo, payMode, payType)
            }, 1000)
          } else {
            this.stopTimer()
            this.error = error
            console.log(error)
          }
        }
      )
    }
  },
  created () {
    if (this.$route.query.loading !== 'false') {
      this.payType = this.$route.query.payType
      this.checkOrder(this.$route.query.tradeNo, this.$route.query.payMode || 'stripe', this.payType)
    }
  },
  beforeDestroy () {
    this.$store.commit('mificode', '')
    this.$store.commit('mifiPackage', '')
    this.$store.commit('qrcode', '')
  }
}
</script>

<style lang="less" scoped>
.success-title{
font-size: 56px;
font-weight: bold;
color: #000000;
}
.success-box{
  text-align: center;
  margin-top: 200px;
}
  .p-20{
    padding: 0 20px;
  }
    .p-v-20{
    padding: 20px 20px 0px 20px;
  }
  .bottom-pakage-btn{
    background: #21DA8C;
  font-size: 36px;
font-weight: bold;
color: #FFFFFF;
  }
</style>
